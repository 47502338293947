import { Icon, IconProps } from '@chakra-ui/react';
import { FC } from 'react';

export const LinkedInIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 17 17" fill="none" {...props}>
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.27778 16.3333H14.7222C15.7041 16.3333 16.5 15.5373 16.5 14.5555V2.11103C16.5 1.12919 15.7041 0.333252 14.7222 0.333252H2.27778C1.29594 0.333252 0.5 1.12919 0.5 2.11103V14.5555C0.5 15.5373 1.29594 16.3333 2.27778 16.3333Z"
        fill="#007EBB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2777 14.111H11.9034V10.067C11.9034 8.95825 11.4821 8.33865 10.6045 8.33865C9.64986 8.33865 9.15107 8.98344 9.15107 10.067V14.111H6.86291V6.40727H9.15107V7.44496C9.15107 7.44496 9.83907 6.17191 11.4738 6.17191C13.1079 6.17191 14.2777 7.16975 14.2777 9.23347V14.111ZM4.13313 5.39853C3.35374 5.39853 2.72217 4.76201 2.72217 3.97698C2.72217 3.19194 3.35374 2.55542 4.13313 2.55542C4.91253 2.55542 5.54372 3.19194 5.54372 3.97698C5.54372 4.76201 4.91253 5.39853 4.13313 5.39853ZM2.95162 14.111H5.33759V6.40727H2.95162V14.111Z"
        fill="white"
      />
    </svg>
  </Icon>
);
