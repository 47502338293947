import { FC, useEffect, useMemo, useState } from 'react';
import { CommentMdEditor } from '@/components/pages/question';
import { debounce } from '@/utils';

interface ReplyInputProps {
  defaultReplyValue: string;
  handleReplySubmit: (newReplyValue: string) => void;
  replyValueCacheKey: string;
  handleCancel: () => void;
  isLoading: boolean;
}

const ReplyComment: FC<ReplyInputProps> = ({
  defaultReplyValue,
  handleReplySubmit,
  handleCancel,
  isLoading,
  replyValueCacheKey,
}) => {
  const [replyCommentValue, setReplyCommentValue] = useState(defaultReplyValue);
  const isSubmitDisabled = replyCommentValue.length === defaultReplyValue.length;

  const saveReplyValueToCache = useMemo(
    () =>
      debounce((value: string) => {
        localStorage.setItem(replyValueCacheKey, value);
      }, 1000),
    [replyValueCacheKey],
  );

  const handleInputChange = (newVal?: string) => {
    if (typeof newVal === 'string') {
      setReplyCommentValue(newVal);
      saveReplyValueToCache(newVal);
    }
  };

  useEffect(() => {
    const savedReplyInput = localStorage.getItem(replyValueCacheKey);
    if (savedReplyInput) {
      setReplyCommentValue(savedReplyInput);
    }
  }, [replyValueCacheKey]);

  return (
    <CommentMdEditor
      mdValue={replyCommentValue}
      onSubmit={() => handleReplySubmit(replyCommentValue)}
      onCancel={handleCancel}
      onChange={handleInputChange}
      isLoading={isLoading}
      isSubmitDisabled={isSubmitDisabled}
    />
  );
};

export default ReplyComment;
