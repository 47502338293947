import { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Button, Center, ScaleFade, Text } from '@chakra-ui/react';
import { WarningTwoIcon } from '@chakra-ui/icons';
import { useUser } from '@/hooks';
import { Logger, UserService } from '@/services';
import { RESEND_VERIFY_EMAIL_SUCCEEDED_MESSAGE } from '@/constants';
import styles from './Banner.module.scss';

const DEFAULT_BANNER_TEXT = 'Confirm Your Email For More Questions';

interface BannerProps {
  isBannerVisible: boolean;
  hideBanner: () => void;
}

const Banner: FC<BannerProps> = (props) => {
  const { isBannerVisible, hideBanner } = props;
  const { user } = useUser();
  const [{ pendingRequest, bannerMessage }, setBannerState] = useState<{
    pendingRequest: boolean;
    bannerMessage: string;
  }>({
    pendingRequest: false,
    bannerMessage: DEFAULT_BANNER_TEXT,
  });

  const isRequestSuccess = bannerMessage === RESEND_VERIFY_EMAIL_SUCCEEDED_MESSAGE;

  const resendEmail = async () => {
    if (!user || !user.externalIdentifier) {
      return;
    }

    setBannerState((prevState) => ({
      ...prevState,
      pendingRequest: true,
    }));

    try {
      const response = await UserService.resendVerificationToEmail(user?.externalIdentifier);

      setBannerState({
        bannerMessage: response.message,
        pendingRequest: false,
      });
    } catch (error: any) {
      Logger.error(error);
      const serverError = error.response?.data?.error;
      setBannerState({
        bannerMessage: typeof serverError === 'string' ? serverError : 'Something went wrong',
        pendingRequest: false,
      });
    }
  };

  useEffect(() => {
    if (!isRequestSuccess) {
      return;
    }

    const timer = setTimeout(() => {
      hideBanner();
    }, 5 * 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [isRequestSuccess, hideBanner]);

  return (
    <ScaleFade
      in={isBannerVisible}
      className={classNames(styles.bannerWrapper, {
        [styles.successfulBanner]: isRequestSuccess,
      })}
    >
      <Center>
        <Text isTruncated pl="4px" pr="20px">
          <WarningTwoIcon fontSize="14px" mb="4px" mr="4px" h="30px" />
          {bannerMessage}
        </Text>
        {!isRequestSuccess && (
          <Button className={styles.bannerButton} isLoading={pendingRequest} colorScheme="red" onClick={resendEmail}>
            Resend Email
          </Button>
        )}
      </Center>
    </ScaleFade>
  );
};

export default Banner;
