import { FC, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { Button, Td, Tr } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { QuestionStatus } from '@/constants';
import { Submission } from '@/types/models';

interface SubmissionsItemProps {
  submission: Pick<Submission, 'submittedAt' | 'status' | 'query'>;
}

const SubmissionItem: FC<SubmissionsItemProps> = ({ submission }) => {
  const [isSubmissionCopied, setIsSubmissionCopied] = useState(false);

  const statusColor = submission.status === QuestionStatus.Solved ? 'green' : 'red';
  const copySubmission = () => setIsSubmissionCopied(true);

  useEffect(() => {
    if (!isSubmissionCopied) {
      return;
    }

    const timer = setTimeout(() => {
      setIsSubmissionCopied(false);
    }, 3 * 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [isSubmissionCopied]);

  return (
    <Tr>
      <Td>{DateTime.fromISO(submission.submittedAt).toFormat('MM/dd/yyyy HH:mm')}</Td>
      <Td color={statusColor}>{submission.status === QuestionStatus.Solved ? 'Solved' : 'Error'}</Td>
      <Td color={statusColor}>
        <CopyToClipboard text={submission.query} onCopy={copySubmission}>
          <Button
            fontSize="14px"
            w="130px"
            fontWeight="400"
            variant="ghost"
            p="0"
            color={isSubmissionCopied ? statusColor : 'gray.800'}
            colorScheme={statusColor}
          >
            {isSubmissionCopied ? <CheckIcon /> : 'Copy To Clipboard'}
          </Button>
        </CopyToClipboard>
      </Td>
    </Tr>
  );
};

export default SubmissionItem;
