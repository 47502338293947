import { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const BinIcon: FC<IconProps> = (props: IconProps) => (
  <Icon viewBox="0 0 14 16" {...props}>
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.82667 5.99998L8.596 12M5.404 12L5.17333 5.99998M11.8187 3.85998C12.0467 3.89465 12.2733 3.93131 12.5 3.97065M11.8187 3.85998L11.1067 13.1153C11.0776 13.4921 10.9074 13.8441 10.63 14.1008C10.3527 14.3575 9.9886 14.5001 9.61067 14.5H4.38933C4.0114 14.5001 3.64735 14.3575 3.36999 14.1008C3.09262 13.8441 2.92239 13.4921 2.89333 13.1153L2.18133 3.85998M11.8187 3.85998C11.0492 3.74366 10.2758 3.65538 9.5 3.59531M2.18133 3.85998C1.95333 3.89398 1.72667 3.93065 1.5 3.96998M2.18133 3.85998C2.95076 3.74366 3.72416 3.65538 4.5 3.59531M9.5 3.59531V2.98465C9.5 2.19798 8.89333 1.54198 8.10667 1.51731C7.36908 1.49374 6.63092 1.49374 5.89333 1.51731C5.10667 1.54198 4.5 2.19865 4.5 2.98465V3.59531M9.5 3.59531C7.83581 3.4667 6.16419 3.4667 4.5 3.59531"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </Icon>
);
