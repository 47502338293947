import { FC, KeyboardEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import TagManager from 'react-gtm-module';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import Image from 'next/image';
import { useRouter } from 'next/router';
import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
  useMediaQuery,
  useOutsideClick,
} from '@chakra-ui/react';
import { useEffectOnce, useEffectOnceWhen, useUser } from '@/hooks';
import { checkIsPremiumUser, getUserName } from '@/utils';
import { GTMEvent, Routes, SIGN_IN_REDIRECT_PATH_KEY } from '@/constants';
import { AppLink, Avatar, LogoIcon, ShareIcon, ShareModal } from '@/components/common';
import { SignInButton, SignOutButton } from '@clerk/nextjs';
import { handleSaveRedirectUrl } from '@/utils/handleSaveRedirectUrl';
import { Question as BaseQuestion } from '@/types/models';
import { QuestionNavigation, Timer } from '@/components/pages/question';
import { BurgerMenu, MenuIcon } from '.';

export interface HeaderProps extends Partial<Pick<BaseQuestion, 'id' | 'company' | 'category'>> {
  isGeneralAssemblyMvp?: boolean;
  isQuestionPage?: boolean;
}

export interface HeaderNavItem {
  title: string;
  path: string;
}

const NAV_ITEMS: HeaderNavItem[] = [
  { title: 'Practice Interview Questions', path: Routes.Questions },
  { title: 'Learn SQL', path: Routes.SQLTutorial },
  { title: 'Get 1:1 Coaching', path: Routes.Pricing },
];

const NICKNAME_ELLIPSIS_WIDTH = '110px';

export const Header: FC<HeaderProps> = (props) => {
  const { isGeneralAssemblyMvp, isQuestionPage = false, company, category, id: questionId } = props;

  const { isOpen: isMenuOpen, onToggle } = useDisclosure();
  const { isOpen: isShareModalOpen, onToggle: onShareModalToggle } = useDisclosure();

  const [
    isLargerThan710,
    isLargerThan890,
    isLargerThan990,
    isLargerThan1010,
    isLargerThan1110,
    isLargerThan1140,
    isLargerThan1240,
  ] = useMediaQuery(
    [
      '(min-width: 710px)',
      '(min-width: 890px)',
      '(min-width: 990px)',
      '(min-width: 1010px)',
      '(min-width: 1110px)',
      '(min-width: 1140px)',
      '(min-width: 1240px)',
    ],
    {
      ssr: true,
      fallback: false,
    },
  );

  const menuRef = useRef<HTMLDivElement | null>(null);
  const [isOutsideClick, setIsOsOutsideClick] = useState<boolean>(false);
  const { asPath } = useRouter();

  const { user, isAuthInitialized, isLoading, logout } = useUser();
  const userName = user ? getUserName(user) : '';
  const isPremiumUser = useMemo(() => checkIsPremiumUser(user), [user]);

  const AUTHORIZED_NAV_ITEMS = user ? NAV_ITEMS : NAV_ITEMS.filter(({ path }) => path !== Routes.Pricing);

  const handleLogout = () => {
    logout();
  };

  useOutsideClick({
    enabled: isMenuOpen,
    ref: menuRef,
    handler: () => {
      setIsOsOutsideClick(true);
      onToggle();
    },
  });

  useEffect(() => {
    if (isMenuOpen && menuRef.current) {
      disableBodyScroll(menuRef.current);
    } else {
      clearAllBodyScrollLocks();
    }
  }, [isMenuOpen]);

  useEffect(() => {
    if (isMenuOpen) {
      onToggle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asPath]);

  useEffectOnce(() => {
    localStorage.removeItem(SIGN_IN_REDIRECT_PATH_KEY);

    if (user) {
      return;
    }

    // login();

    return () => {
      if (isMenuOpen) {
        onToggle();
      }
    };
  });

  const handleEsc = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Escape' && isMenuOpen) {
        onToggle();
      }
    },
    [isMenuOpen, onToggle],
  );

  const handleMenuIconClick = useCallback(() => {
    if (isOutsideClick) return setIsOsOutsideClick(false);

    onToggle();
  }, [isOutsideClick, onToggle]);

  useEffectOnceWhen(() => {
    // Condition to avoid TS type errors
    if (!user) {
      return;
    }

    TagManager.dataLayer({
      dataLayer: {
        user_id: user.id,
        event: GTMEvent.UserDetails,
        login_status: 'true',
        customer_type: isPremiumUser ? 'Paying' : 'Free',
      },
    });
  }, user && asPath);

  const nicknameWidth = useMemo(() => {
    if (isPremiumUser) {
      if (!isLargerThan1140) {
        return NICKNAME_ELLIPSIS_WIDTH;
      }

      return 'unset';
    }

    if (!isLargerThan1240) {
      return NICKNAME_ELLIPSIS_WIDTH;
    }

    return 'unset';
  }, [isPremiumUser, isLargerThan1140, isLargerThan1240]);

  return (
    <Flex justifyContent="center" h="56px" position="fixed" top={0} right={0} left={0} bg="gray.100" px="4" zIndex={10}>
      <Box maxW={{ base: '100%', extraLarge: '1512px' }} width="100%">
        <ShareModal
          isModalOpen={isShareModalOpen}
          onClose={onShareModalToggle}
          company={company}
          category={category}
          isQuestionPage={isQuestionPage}
        />

        <Flex
          position="relative"
          h="14"
          gap="30px"
          alignItems="center"
          justifyContent="space-between"
          onKeyDown={handleEsc}
          zIndex={100}
        >
          <Center gap="16px" flexShrink={0}>
            <AppLink href={Routes.Home} display="flex" mb="4px">
              {isGeneralAssemblyMvp ? (
                <Image src="/general_assembly_logo.png" width={290} height={32} alt="logo" />
              ) : (
                <LogoIcon width="161px" height="32px" />
              )}
            </AppLink>

            <Flex as="nav" hidden={!isLargerThan890 || isQuestionPage}>
              {AUTHORIZED_NAV_ITEMS.map(({ title, path }) => (
                <Button
                  key={title}
                  as={AppLink}
                  href={path}
                  variant="wrapper"
                  fontFamily="body"
                  fontSize="18px"
                  color="black.500"
                  _hover={{ textDecoration: 'none', bg: 'gray.200' }}
                >
                  {title}
                </Button>
              ))}
            </Flex>

            <HStack gap="16px" hidden={!isLargerThan710 || !isQuestionPage}>
              <QuestionNavigation id={questionId!} />

              <Timer />
            </HStack>
          </Center>

          <Flex gap="8px" alignItems="center">
            {isLargerThan890 && isAuthInitialized && user && (
              <Menu>
                <MenuButton
                  as={Button}
                  rounded="full"
                  variant="link"
                  cursor="pointer"
                  minW="0"
                  _hover={{ textDecoration: 'none' }}
                >
                  <Center gap="6px">
                    <Avatar variant="outline" size="xs" mt="3px" avatarId={user.avatar} userName={userName} />

                    <Text
                      isTruncated={isPremiumUser ? !isLargerThan1140 : !isLargerThan1240}
                      width={nicknameWidth}
                      fontWeight={600}
                      color="black.500"
                      textAlign="right"
                      fontFamily="body"
                      wordBreak="break-word"
                    >
                      {userName}
                    </Text>
                  </Center>
                </MenuButton>
                <MenuList>
                  <MenuItem>
                    <AppLink href={Routes.ProfilePerformance} w="100%" _hover={{ textDecor: 'none' }}>
                      Your Performance
                    </AppLink>
                  </MenuItem>
                  <MenuItem>
                    <AppLink href={Routes.AccountSettings} w="100%" _hover={{ textDecor: 'none' }}>
                      Account Settings
                    </AppLink>
                  </MenuItem>
                  <MenuItem>
                    <AppLink href={Routes.Premium} w="100%" _hover={{ textDecor: 'none' }}>
                      Premium
                    </AppLink>
                  </MenuItem>
                  <SignOutButton redirectUrl={asPath}>
                    <MenuItem onClick={!isLoading ? handleLogout : undefined}>Log out</MenuItem>
                  </SignOutButton>
                </MenuList>
              </Menu>
            )}

            <Button
              hidden={isPremiumUser ? !isLargerThan990 : !isLargerThan1110}
              variant="wrapper"
              color="black.500"
              fontWeight={600}
              maxH="32px"
              fontFamily="body"
              leftIcon={<ShareIcon />}
              onClick={onShareModalToggle}
            >
              Share
            </Button>

            <Button
              hidden={!user || !isLargerThan1010 || isPremiumUser}
              as={AppLink}
              href={Routes.Premium}
              maxH="32px"
              variant="primaryCustom"
              colorScheme="red"
              isDisabled={isLoading}
              onClick={() => handleSaveRedirectUrl(asPath)}
            >
              Premium
            </Button>

            {isLargerThan890 && isAuthInitialized && !user && (
              <Flex alignItems="center">
                <SignInButton>
                  <Button
                    maxH="32px"
                    variant="primaryCustom"
                    colorScheme="red"
                    isDisabled={isLoading}
                    onClick={() => handleSaveRedirectUrl(asPath)}
                  >
                    Sign In
                  </Button>
                </SignInButton>
              </Flex>
            )}
          </Flex>

          {!isLargerThan890 && <MenuIcon isOpen={isMenuOpen} handleClick={handleMenuIconClick} />}
        </Flex>

        <BurgerMenu menuItems={AUTHORIZED_NAV_ITEMS} isOpen={isMenuOpen} forwardedRef={menuRef} />
      </Box>
    </Flex>
  );
};
