import { Icon, IconProps } from '@chakra-ui/react';

export const ShareIcon = (props: IconProps): JSX.Element => (
  <Icon viewBox="0 0 16 16" fill="none" {...props}>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 5.5H5C4.60218 5.5 4.22064 5.65804 3.93934 5.93934C3.65804 6.22064 3.5 6.60218 3.5 7V13C3.5 13.3978 3.65804 13.7794 3.93934 14.0607C4.22064 14.342 4.60218 14.5 5 14.5H11C11.3978 14.5 11.7794 14.342 12.0607 14.0607C12.342 13.7794 12.5 13.3978 12.5 13V7C12.5 6.60218 12.342 6.22064 12.0607 5.93934C11.7794 5.65804 11.3978 5.5 11 5.5H10M10 3.5L8 1.5M8 1.5L6 3.5M8 1.5V10"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </Icon>
);
