import { COMMENT_FIELDS } from '@/constants/pages';
import { RequestOptions } from '@/types/api';

export const getCommentsRequestOptions = ({
  questionId,
  userId,
  isParentIdNull,
  limit,
  page,
}: {
  questionId?: number;
  userId?: string;
  isParentIdNull: boolean;
  limit: number;
  page?: number;
}): RequestOptions => ({
  fields: COMMENT_FIELDS,
  sort: '-upvotes',
  filter: {
    deleted_at: { _null: true },
    parent_id: { _null: isParentIdNull },
    ...(questionId && { question_id: { _eq: questionId } }),
    ...(userId && {
      user_id: { _eq: userId },
    }),
  },
  limit,
  page,
});
