import { FC } from 'react';
import { Heading } from '@chakra-ui/react';
import { Markdown } from '@/components/common';

interface SolutionProps {
  solutionText: string | null;
}

const Solution: FC<SolutionProps> = ({ solutionText }) => {
  return (
    <>
      <Heading as="h3" size="md" py="10px">
        Solution
      </Heading>
      {solutionText && <Markdown>{solutionText}</Markdown>}
    </>
  );
};

export default Solution;
