import { FC } from 'react';
import { Box, Button, Center, Heading, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { ProductPlan } from '@/types/models';
import { getBillingPlanTitle, getCurrentPlanPrice, getOldPlanPrice } from '@/utils';
import { ProductPlanPrice } from '@/components/pages/checkout';
import { BillingPlanTitle, BillingPlanType } from '@/constants';
import styles from './PlanDetails.module.scss';

interface PlanDetailsProps {
  productPlans: ProductPlan[];
  currentPlan: ProductPlan;
  selectedBillingPlan: BillingPlanType;
  onPlanChange: (value: BillingPlanType) => void;
}

const PlanDetails: FC<PlanDetailsProps> = (props) => {
  const { productPlans, selectedBillingPlan, onPlanChange, currentPlan } = props;

  const isSelectedFreePlan = selectedBillingPlan === BillingPlanType.Free;
  const selectedPlanTitle = getBillingPlanTitle(selectedBillingPlan);
  const currentPlanPrice = getCurrentPlanPrice(currentPlan.type, currentPlan.price);

  return (
    <Box>
      <Box className={styles.buttonsContainer}>
        {productPlans
          .filter((plan) => plan.type !== BillingPlanType.Free)
          .map((plan) => (
            <Button
              key={plan.id}
              className={styles.button}
              colorScheme="red"
              variant={selectedBillingPlan !== plan.type ? 'outline' : undefined}
              onClick={() => onPlanChange(plan.type)}
            >
              {plan.type === BillingPlanType.Lifetime ? BillingPlanTitle.Lifetime : plan.title}
            </Button>
          ))}
      </Box>
      <Center py="20px" mt="16px" mb="10px" flexDir="column" backgroundColor="red.400" borderRadius="8px" color="white">
        <Heading as="h4" fontSize="24px" textAlign="center">
          {selectedPlanTitle === BillingPlanTitle.Lifetime ? currentPlan.title : `${selectedPlanTitle} Plan`}
        </Heading>
        {!isSelectedFreePlan && (
          <ProductPlanPrice
            currentPlanPrice={currentPlanPrice}
            oldPlanPrice={getOldPlanPrice(currentPlan.oldPrice)}
            subTitle={currentPlan.shortDescription}
          />
        )}
      </Center>
      {/*<Input fontSize="20px" h="60px" mb="10px" placeholder="Enter Discount Code" />}*/}
      <TableContainer>
        <Table className={styles.table}>
          <Thead backgroundColor="gray.50">
            <Tr>
              <Th colSpan={2} textAlign="center" fontSize="16px" textTransform="none">
                Order Summary
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {/*<Tr>*/}
            {/*  <Td>{selectedPlanTitle} subscription</Td>*/}
            {/*  <Td>{currentPlanPrice}</Td>*/}
            {/*</Tr>*/}
            <Tr fontWeight="700">
              <Td>Total due today</Td>
              <Td>{currentPlanPrice}</Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default PlanDetails;
