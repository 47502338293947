import { Icon, IconProps } from '@chakra-ui/react';

export const CrossIcon = (props: IconProps): JSX.Element => (
  <Icon viewBox="0 0 11 10" fill="none" {...props}>
    <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.5 9L9.5 1M1.5 1L9.5 9"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </Icon>
);
