import React, { FC } from 'react';
import { useRouter } from 'next/router';
import { Flex } from '@chakra-ui/react';
import { Loader, Markdown, Share } from '@/components/common';
import { Hints } from '@/components/pages/question';
import { getSiteUrl, SectionAnchor } from '@/utils';
import { Question } from '@/types/pages/question';
import { Hint } from '@/types/models';
import { AccessGroup, Routes } from '@/constants';
import { GuardedQuestionBadge, GuardedQuestionBadgeProps } from './components';

type QuestionDetailsProps = Pick<Question, 'description' | 'company' | 'isGuarded' | 'category' | 'accessGroups'> & {
  hints: Hint[];
  isMobile: boolean;
  isQuestionLoading: boolean;
  isGeneralAssemblyMvp?: boolean;
};

const QuestionDetails: FC<QuestionDetailsProps> = (props) => {
  const {
    description,
    company,
    category,
    hints,
    isGuarded,
    isQuestionLoading,
    isGeneralAssemblyMvp,
    isMobile,
    accessGroups,
  } = props;

  const { asPath } = useRouter();

  const guardedBadgeDetails: GuardedQuestionBadgeProps = accessGroups?.includes(AccessGroup.PremiumQuestions)
    ? {
        title: 'Subscribe to unlock.',
        subTitle: 'Thanks for using DataLemur! To view this question you must subscribe to premium.',
        path: Routes.Pricing,
        ctaTitle: 'Subscribe',
      }
    : {
        title: 'Bonus question locked.',
        subTitle: 'Thanks for using DataLemur! To view this question you must complete referral task',
        path: `${Routes.Questions}#${SectionAnchor.ReferralSection}`,
        ctaTitle: 'Learn more',
      };

  const areHintsEmpty = !hints.length;
  const sharingUrl = getSiteUrl(asPath.split('?')[0]);
  const companyForHashtag = company?.split(',')[0] || '';
  const hashtags = ['DataLemur', category, companyForHashtag].filter(Boolean);

  return (
    <>
      <Flex mb="12px" alignItems="center" flexWrap="wrap">
        {!isGeneralAssemblyMvp && (
          <>
            <Share url={sharingUrl} hashtags={hashtags} isMobile={isMobile} />
          </>
        )}
      </Flex>
      {isQuestionLoading ? (
        <Loader height="500px" />
      ) : (
        <>
          {isGuarded ? (
            <GuardedQuestionBadge {...guardedBadgeDetails} />
          ) : (
            <>
              {description && <Markdown>{description}</Markdown>}
              {!areHintsEmpty && <Hints hints={hints} />}
            </>
          )}
        </>
      )}
    </>
  );
};

export default QuestionDetails;
