import { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const LampIcon: FC<IconProps> = (props: IconProps) => (
  <Icon viewBox="0 0 16 16" {...props}>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 12V8.5M8 8.5C8.33731 8.50026 8.6733 8.45793 9 8.374M8 8.5C7.66269 8.50026 7.3267 8.45793 7 8.374M9.5 13.3593C8.50886 13.5476 7.49114 13.5476 6.5 13.3593M9 14.948C8.33515 15.0176 7.66485 15.0176 7 14.948M9.5 12V11.872C9.5 11.2167 9.93867 10.6567 10.5053 10.328C11.4573 9.7767 12.2009 8.92681 12.6209 7.91003C13.0409 6.89325 13.1139 5.76633 12.8284 4.70389C12.543 3.64146 11.9152 2.70281 11.0422 2.03339C10.1692 1.36397 9.09978 1.00115 7.99967 1.00115C6.89956 1.00115 5.83015 1.36397 4.95716 2.03339C4.08416 2.70281 3.45631 3.64146 3.17089 4.70389C2.88546 5.76633 2.9584 6.89325 3.37839 7.91003C3.79839 8.92681 4.542 9.7767 5.494 10.328C6.06067 10.6567 6.5 11.2167 6.5 11.872V12"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </Icon>
);
