import { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const PencilIcon: FC<IconProps> = (props: IconProps) => (
  <Icon viewBox="0 0 16 16" {...props}>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.2413 2.99138L12.366 1.86604C12.6005 1.63159 12.9184 1.49988 13.25 1.49988C13.5816 1.49988 13.8995 1.63159 14.134 1.86604C14.3685 2.10049 14.5002 2.41848 14.5002 2.75004C14.5002 3.08161 14.3685 3.39959 14.134 3.63404L4.55467 13.2134C4.20222 13.5656 3.76758 13.8245 3.29 13.9667L1.5 14.5L2.03333 12.71C2.17552 12.2325 2.43442 11.7978 2.78667 11.4454L11.242 2.99138H11.2413ZM11.2413 2.99138L13 4.75004"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </Icon>
);
