import { create } from 'zustand';
import { Tag } from '@/types/models';
import { Question, QuestionsQuery } from '@/types/pages/questions';

interface QuestionsStore {
  questions: Question[] | null;
  setQuestions: (questions: Question[]) => void;
  filterParams: QuestionsQuery | null;
  setFilterParams: (params: QuestionsQuery | null) => void;
  tags: Tag[] | null;
  setTags: (tags: Tag[]) => void;
}

export const useQuestionsStore = create<QuestionsStore>((set) => ({
  questions: null,
  setQuestions: (questions) => set({ questions }),
  filterParams: null,
  setFilterParams: (filterParams) => set({ filterParams }),
  tags: null,
  setTags: (tags) => set({ tags }),
}));
