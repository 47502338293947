import { FC } from 'react';
import Image from 'next/image';
import classNames from 'classnames';
import { Flex } from '@chakra-ui/react';
import styles from './Testimonial.module.scss';

export interface TestimonialOptions {
  companyLogoSrc: string;
  companyName: string;
  text: string;
  author: string;
  authorPosition: string;
}

interface TestimonialProps extends TestimonialOptions {
  className?: string;
}

const Testimonial: FC<TestimonialProps> = (props) => {
  const { className, companyLogoSrc, companyName, text, author, authorPosition } = props;

  return (
    <Flex className={classNames(styles.container, className)} flexDirection="column">
      <Image
        className={styles.commonLogo}
        src={companyLogoSrc}
        alt={`${companyName}'s logo`}
        width={246}
        height={100}
      />
      <figure>
        <blockquote className={styles.quote}>&quot;{text}&quot;</blockquote>
        <figcaption>
          &mdash; {author} <br />
          <span className={styles.authorPosition}>{authorPosition}</span>
        </figcaption>
      </figure>
    </Flex>
  );
};

export default Testimonial;
