import React, { FC } from 'react';
import { Button, Center, Flex, Modal, ModalBody, ModalContent, ModalOverlay, Text } from '@chakra-ui/react';
import Link from 'next/link';
import { formatISODateToShortDate } from '@/utils';

interface UnsubscribeModalProps {
  subscriptionEndsAt: string | null | undefined;
  isModalOpen: boolean;
  onClose: () => void;
}

const UnsubscribeModal: FC<UnsubscribeModalProps> = ({ subscriptionEndsAt, isModalOpen = false, onClose }) => {
  const subscriptionEndDate = subscriptionEndsAt ? formatISODateToShortDate(subscriptionEndsAt) : null;

  return (
    <Modal isCentered onClose={onClose} isOpen={isModalOpen} motionPreset="scale">
      <ModalOverlay />
      <ModalContent minH="360px" padding="20px" bg="gray.100">
        <ModalBody display="flex" justifyContent="center" alignItems="center">
          <Center flexDirection="column">
            <Flex flexDirection="column" gap="15px">
              <Text>
                We&apos;re sorry to see you go. Your DataLemur subscription will be cancelled
                {subscriptionEndDate
                  ? ` at the end of the billing
                cycle on ${subscriptionEndDate}`
                  : `.`}
              </Text>
              <Text>Until then you&apos;ll still have an access to the Premium questions.</Text>
              <Text>Thanks for using DataLemur. We hope you enjoyed your experience.</Text>
              <Text>
                ps: if you&apos;ve had second thoughts and did not want to cancel your subscription,{' '}
                <Link href="mailto:nick@datalemur.com" style={{ color: 'blue' }}>
                  email
                </Link>{' '}
                me!!
              </Text>
              <Flex justifyContent="center">
                <Button maxH="32px" autoFocus colorScheme="red" onClick={onClose}>
                  Close
                </Button>
              </Flex>
            </Flex>
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default UnsubscribeModal;
