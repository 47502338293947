import { Flex, FlexProps, Grid, GridItem, Heading, Text } from '@chakra-ui/react';
import { Routes } from '@/constants';
import { LogoIcon } from '@/components/common';
import AppLink from '../AppLink';
import { careerResourcesColumn, interviewQuestionsColumn, supportColumn } from './footerLinks';

const Footer = ({ ...containerProps }: FlexProps) => (
  <Flex bg="gray.100" pb="24px" pt="34px" mt="auto" px={{ base: '16px' }} {...containerProps}>
    <Grid maxWidth="1400px" templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }} gap="24px">
      <Flex flexDir="column" justifyContent="space-between" display={{ base: 'none', md: 'flex' }}>
        <AppLink href={Routes.Home} display="flex">
          <LogoIcon width="161px" height="32px" />
        </AppLink>

        <Text>© {new Date().getFullYear()} DataLemur, Inc</Text>
      </Flex>

      <Flex flexDir="column" gap="40px">
        <Flex direction="column" gap="8px">
          <Heading as="h3" fontSize="16px" lineHeight="24px">
            {careerResourcesColumn.title}
          </Heading>
          {careerResourcesColumn.links.map(({ text, href, inNewTab }, linkIndex) => (
            <AppLink
              key={linkIndex}
              href={href}
              target={inNewTab ? '_blank' : undefined}
              rel="noopener noreferrer"
              color="black.200"
              _hover={{ color: 'black.500' }}
            >
              {text}
            </AppLink>
          ))}
        </Flex>

        <Flex direction="column" gap="8px">
          <Heading as="h3" fontSize="16px" lineHeight="24px">
            {supportColumn.title}
          </Heading>
          {supportColumn.links.map(({ text, href, inNewTab }, linkIndex) => (
            <AppLink
              key={linkIndex}
              href={href}
              target={inNewTab ? '_blank' : undefined}
              rel="noopener noreferrer"
              color="black.200"
              _hover={{ color: 'black.500' }}
            >
              {text}
            </AppLink>
          ))}
        </Flex>
      </Flex>

      <GridItem>
        <Flex direction="column" gap="8px">
          <Heading as="h3" fontSize="16px" lineHeight="24px">
            {interviewQuestionsColumn.title}
          </Heading>
          {interviewQuestionsColumn.links.map(({ text, href, inNewTab }, linkIndex) => (
            <AppLink
              key={linkIndex}
              href={href}
              target={inNewTab ? '_blank' : undefined}
              rel="noopener noreferrer"
              color="black.200"
              _hover={{ color: 'black.500' }}
            >
              {text}
            </AppLink>
          ))}
        </Flex>
      </GridItem>
    </Grid>
  </Flex>
);

export default Footer;
