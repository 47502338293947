export const getFuncNameFromPythonQuery = (pythonQuery: string) => {
  // Regular expression to match function definitions
  const regex = /def\s+([a-zA-Z_]\w*)\s*\(/g;
  let match;
  let lastFunctionName = null;

  // Iterate over all matches
  while ((match = regex.exec(pythonQuery)) !== null) {
    lastFunctionName = match[1]; // Update lastFunctionName with the latest match
  }

  return lastFunctionName;
};
