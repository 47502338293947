import { FC, useState, ReactNode } from 'react';

import { useEffectOnce } from '@/hooks';

// TODO We potentially need to remove it after completing migration to Clerk
const ClientOnly: FC<{ children: ReactNode }> = ({ children }) => {
  const [isMounted, setIsMounted] = useState<boolean>(false);

  useEffectOnce(() => {
    setIsMounted(true);
  });

  return isMounted ? <>{children}</> : null;
};

export default ClientOnly;
