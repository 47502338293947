import { VoteOutcome } from '@/constants';
import { RELATIVE_TIME_DEFAULT } from '@/constants/defaultValues';
import { DateTime } from 'luxon';

export const getRelativeCommentTime = (timestamp: string): string => {
  const date = new Date(timestamp);
  const relativeTime = DateTime.fromJSDate(date).toRelative({ locale: 'en' });

  return relativeTime ? relativeTime : RELATIVE_TIME_DEFAULT;
};

export enum TriangleDirection {
  Up,
  Down,
}

export const getTriangleIconColor = (voteOutcome: VoteOutcome | null, direction: TriangleDirection): string => {
  if (voteOutcome === VoteOutcome.Downvote && direction === TriangleDirection.Down) {
    return 'red.850';
  }
  if (voteOutcome === VoteOutcome.Upvote && direction === TriangleDirection.Up) {
    return 'green.300';
  }

  return 'black.300';
};
