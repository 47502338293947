import { FC, useMemo } from 'react';
import classNames from 'classnames';
import Link from 'next/link';
import { Box, Heading, ListItem, UnorderedList } from '@chakra-ui/react';
import { useMediaQuery } from '@chakra-ui/media-query';
import { SidebarLesson } from '@/types/pages/lesson';
import { LessonCategory, MAP_LESSON_CATEGORY_TO_TEXT, Routes } from '@/constants';
import { filterLessonsByCategory } from '@/utils';
import styles from './Sidebar.module.scss';

interface NavBarProps {
  lessons: SidebarLesson[];
  currentLessonSlug: string;
  selectedCategory: LessonCategory;
}

interface CategoryListProps {
  lessons: SidebarLesson[];
  selectedCategory: LessonCategory;
  currentLessonSlug: string;
}

const CategoryList: FC<CategoryListProps> = (props) => {
  const { lessons, selectedCategory, currentLessonSlug } = props;

  const isLessonsArrayEmpty = !lessons.length;
  const currentLessonCategory = MAP_LESSON_CATEGORY_TO_TEXT[lessons[0]?.category];
  const isCurrentCategorySelected = selectedCategory === lessons[0]?.category;

  return (
    <>
      {!isLessonsArrayEmpty && (
        <UnorderedList className={styles.categoryList}>
          <ListItem>
            <Link
              href={`${Routes.SQLTutorial}/${lessons[0].slug}`}
              className={styles.lessonLinkTitle}
              rel="noopener noreferrer"
            >
              {currentLessonCategory}
            </Link>
          </ListItem>
          {isCurrentCategorySelected && (
            <UnorderedList className={styles.lessonsList}>
              {lessons.map((lesson) => {
                const isCurrentLesson = lesson.slug === currentLessonSlug;
                return (
                  <ListItem
                    key={lesson.slug}
                    className={classNames(styles.lessonItem, {
                      [styles.currentLessonItem]: isCurrentLesson,
                    })}
                  >
                    <Link
                      href={`${Routes.SQLTutorial}/${lesson.slug}`}
                      className={styles.lessonLink}
                      rel="noopener noreferrer"
                    >
                      {lesson.shortTitle}
                    </Link>
                  </ListItem>
                );
              })}
            </UnorderedList>
          )}
        </UnorderedList>
      )}
    </>
  );
};

const Sidebar: FC<NavBarProps> = (props) => {
  const { lessons, currentLessonSlug, selectedCategory } = props;
  const [isLargerThan1030px] = useMediaQuery(['(min-width: 1030px)']);

  const basicLessons = useMemo(() => filterLessonsByCategory(lessons, LessonCategory.Basic), [lessons]);
  const intermediateLessons = useMemo(() => filterLessonsByCategory(lessons, LessonCategory.Intermediate), [lessons]);
  const advancedLessons = useMemo(() => filterLessonsByCategory(lessons, LessonCategory.Advanced), [lessons]);

  return (
    <Box className={styles.navBar} display={isLargerThan1030px ? 'block' : 'none'}>
      <Link href={Routes.SQLTutorial} rel="noopener noreferrer">
        <Heading as="h3" fontSize="26px" mb="10px">
          SQL Tutorial
        </Heading>
      </Link>
      <CategoryList lessons={basicLessons} selectedCategory={selectedCategory} currentLessonSlug={currentLessonSlug} />
      <CategoryList
        lessons={intermediateLessons}
        selectedCategory={selectedCategory}
        currentLessonSlug={currentLessonSlug}
      />
      <CategoryList
        lessons={advancedLessons}
        selectedCategory={selectedCategory}
        currentLessonSlug={currentLessonSlug}
      />
    </Box>
  );
};

export default Sidebar;
