import { ExternalLinks, Routes, PostLink } from '@/constants';

interface ColumnLink {
  text: string;
  href: string;
  inNewTab?: boolean;
}

interface FooterColumn {
  title: string;
  links: ColumnLink[];
}
export const careerResourcesColumn: FooterColumn = {
  title: 'Career Resources',
  links: [
    {
      text: 'Free 9-Day Data Interview Crash Course',
      href: ExternalLinks.AceTheDataScienceNineDaysCourse,
      inNewTab: true,
    },
    {
      text: 'Free SQL Tutorial for Data Analytics',
      href: Routes.SQLTutorial,
    },
    {
      text: 'Ultimate SQL Interview Guide',
      href: `${Routes.Blog}/${PostLink.UltimateSQLInterviewGuide}`,
    },
    {
      text: 'Ace the Data Job Hunt Video Course',
      href: ExternalLinks.AceTheDataScienceLink,
      inNewTab: true,
    },
    {
      text: 'Ace the Data Science Interview',
      href: ExternalLinks.BookLink,
      inNewTab: true,
    },
    {
      text: 'Best Books for Data Analysts',
      href: ExternalLinks.BestBooks,
      inNewTab: true,
    },
  ],
};

export const supportColumn: FooterColumn = {
  title: 'Support',
  links: [
    { text: 'DataLemur Blog', href: Routes.Blog },
    { text: 'Privacy Policy', href: Routes.PrivacyPolicy },
    { text: 'Terms of Service', href: Routes.TermsOfService },
    { text: 'Email Us', href: 'mailto:nick@datalemur.com' },
  ],
};

export const interviewQuestionsColumn: FooterColumn = {
  title: 'Interview Questions',
  links: [
    { text: 'Data Science Interview Questions', href: Routes.Questions },
    { text: 'SQL Interview Questions', href: Routes.SQLQuestions },
    { text: 'Python Coding Interview Questions', href: Routes.PythonQuestions },
    {
      text: 'Statistics Interview Questions',
      href: `${Routes.Blog}/statistics-interview-questions-data-science`,
    },
    { text: 'Machine Learning Interview Questions', href: Routes.MachineLearningQuestions },
    { text: 'Meta Data Science Interview Guide', href: `${Routes.Blog}/meta-data-scientist-interview-guide` },
    { text: 'TikTok Data Science Interview Guide', href: `${Routes.Blog}/tiktok-data-scientist-interview-guide` },
    { text: 'Amazon Data Science Interview Guide', href: `${Routes.Blog}/amazon-data-scientist-interview-guide` },
    { text: 'Amazon SQL Interview Questions', href: `${Routes.Blog}/amazon-sql-interview-questions` },
    { text: 'Meta / Facebook SQL Interview Questions', href: `${Routes.Blog}/facebook-sql-interview-questions` },
    { text: 'Google SQL Interview Questions', href: `${Routes.Blog}/google-sql-interview-questions` },
    { text: 'Microsoft SQL Interview Questions', href: `${Routes.Blog}/microsoft-sql-interview-questions` },
    { text: 'Apple SQL Interview Questions', href: `${Routes.Blog}/apple-sql-interview-questions` },
  ],
};
