import { Button, Center, Heading, useDisclosure } from '@chakra-ui/react';
import { ChevronRightIcon, LockIcon } from '@chakra-ui/icons';
import { useRouter } from 'next/router';
import { LoginModal } from '@/components/common';
import { useUser } from '@/hooks';

export interface GuardedQuestionBadgeProps {
  title: string;
  subTitle: string;
  path: string;
  ctaTitle: string;
}

export const GuardedQuestionBadge = ({ title, subTitle, path, ctaTitle }: GuardedQuestionBadgeProps) => {
  const { push } = useRouter();
  const { user } = useUser();
  const { isOpen: isOpenModal, onOpen: onOpenModal, onClose } = useDisclosure();

  const buttonHandler = () => (user ? push(path) : onOpenModal());

  return (
    <>
      <LoginModal isModalOpen={isOpenModal} onClose={onClose} />

      <Center flexDir="column" w="100%" h="100%" minH="300px" color="gray.500">
        <LockIcon fontSize="36px" mb="16px" color="red.500" />
        <Heading mb="10px" textAlign="center" fontWeight="500" fontSize="18px">
          {title}
        </Heading>
        <Heading maxW="400px" mb="30px" textAlign="center" fontWeight="500" fontSize="16px">
          {subTitle}
        </Heading>
        <Button onClick={buttonHandler} autoFocus colorScheme="red" rightIcon={<ChevronRightIcon />}>
          {ctaTitle}
        </Button>
      </Center>
    </>
  );
};
