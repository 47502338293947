import { Icon, IconProps } from '@chakra-ui/react';

export const RefreshIcon = (props: IconProps): JSX.Element => (
  <Icon viewBox="0 0 14 14" fill="none" {...props}>
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.31734 5.23196H0.989343L3.11001 3.10996C3.79275 2.42721 4.64321 1.93622 5.57587 1.68636C6.50853 1.4365 7.49054 1.43658 8.42316 1.68658C9.35579 1.93657 10.2062 2.42768 10.8888 3.11054C11.5715 3.79339 12.0623 4.64392 12.312 5.57663M13.0093 12.096V8.76796M13.0093 8.76796H9.68134M13.0093 8.76796L10.8893 10.89C10.2066 11.5727 9.35615 12.0637 8.42348 12.3136C7.49082 12.5634 6.50882 12.5633 5.57619 12.3133C4.64356 12.0633 3.79319 11.5722 3.11054 10.8894C2.4279 10.2065 1.93705 9.356 1.68734 8.42329M0.989343 1.90396V5.23063"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </Icon>
);
