import { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';

interface EmptyQueryTableProps {
  title?: string;
}

const EmptyQueryTable: FC<EmptyQueryTableProps> = ({ title }) => (
  <Flex flexDirection="column" gap="4px">
    <Text mt="16px" fontWeight="500">
      {title}
    </Text>
    <Text fontWeight="500" fontSize="18px" color="gray.400" mr="20px">
      There is no data to display.
    </Text>
  </Flex>
);

export default EmptyQueryTable;
