import { FC } from 'react';
import Link from 'next/link';
import { Button, Container, Text } from '@chakra-ui/react';
import { Layout } from '@/components/layout';
import { Routes } from '@/constants';

const InternalServerErrorFallback: FC = () => (
  <Layout tabTitle="500">
    <Container pt="50px" textAlign="center" fontSize="24px" flexDir="column" alignItems="center">
      <Text>500 | Internal server error</Text>
      <Link href={Routes.Questions} passHref legacyBehavior>
        <Button as="a" mt="20px" colorScheme="red" variant="solid">
          Go to questions
        </Button>
      </Link>
    </Container>
  </Layout>
);

export default InternalServerErrorFallback;
