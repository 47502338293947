import { FC } from 'react';
import Link from 'next/link';
import { Box, Text } from '@chakra-ui/react';
import styles from './ProductCard.module.scss';

interface OptionItemProps {
  value: string;
}

const SEPARATOR_CHARS = '{sep}';
const LINK_START_CHARS = 'http';
const LINK_END_CHARS = '{end}';

const OptionItem: FC<OptionItemProps> = (props) => {
  const { value } = props;

  if (value.includes(LINK_START_CHARS)) {
    const linkStartChar = value.indexOf(LINK_START_CHARS);
    const linkLastChar = value.indexOf(LINK_END_CHARS);

    const firstPartText = value.slice(0, linkStartChar);
    const lastPartText = value.slice(linkLastChar + LINK_END_CHARS.length);

    const hrefLastCharPos = value.indexOf(SEPARATOR_CHARS);
    const href = value.slice(linkStartChar, hrefLastCharPos);

    const linkText = value.slice(hrefLastCharPos + SEPARATOR_CHARS.length, linkLastChar);

    return (
      <Box>
        {firstPartText}
        <Link href={href} className={styles.link} target="_blank" rel="noopener noreferrer">
          <Text wordBreak="break-word">{linkText}</Text>
        </Link>
        {lastPartText}
      </Box>
    );
  }

  return <Text>{value}</Text>;
};

export default OptionItem;
