import { FC } from 'react';
import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { MAP_RUNTIMES_TO_TEXT, SupportedRuntime } from '@/constants';

interface MenuQuestionRuntimesProps {
  supportedRuntimes: SupportedRuntime[];
  currentRuntime: SupportedRuntime;
  changeRuntime: (runtime: SupportedRuntime) => void;
}

export const MenuQuestionRuntimes: FC<MenuQuestionRuntimesProps> = ({
  currentRuntime,
  changeRuntime,
  supportedRuntimes,
}) => {
  const shouldShowMockItems =
    !supportedRuntimes ||
    !supportedRuntimes?.length ||
    (supportedRuntimes?.length === 1 && supportedRuntimes?.includes(SupportedRuntime.Postgres));
  const isMySQLRuntimeDisabled = !supportedRuntimes?.includes(SupportedRuntime.MySQL);

  const mockItems: SupportedRuntime[] = [SupportedRuntime.Postgres, SupportedRuntime.MySQL];
  const menuItems = shouldShowMockItems ? mockItems : supportedRuntimes;

  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton
            as={Button}
            isActive={isOpen}
            iconSpacing="6px"
            rightIcon={<ChevronDownIcon color="inherit" />}
            borderRadius="8px"
            // border="1px solid"
            variant="wrapper"
            fontWeight={400}
            borderColor="gray.300"
            backgroundColor="white"
          >
            {currentRuntime && MAP_RUNTIMES_TO_TEXT[currentRuntime]}
          </MenuButton>
          <MenuList zIndex={10}>
            {menuItems?.map((runtime) => {
              const isMySQLDisabled = isMySQLRuntimeDisabled && runtime === SupportedRuntime.MySQL;

              return (
                <MenuItem isDisabled={isMySQLDisabled} key={runtime} onClick={() => changeRuntime(runtime)}>
                  {MAP_RUNTIMES_TO_TEXT[runtime]}
                  {isMySQLDisabled && ' (coming soon⏳)'}
                </MenuItem>
              );
            })}
          </MenuList>
        </>
      )}
    </Menu>
  );
};
