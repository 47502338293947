import { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const CopyIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 13 15" fill="none" {...props}>
    <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 11.167V13.417C9 13.831 8.664 14.167 8.25 14.167H1.75C1.55109 14.167 1.36032 14.088 1.21967 13.9473C1.07902 13.8067 1 13.6159 1 13.417V4.91699C1 4.50299 1.336 4.16699 1.75 4.16699H3C3.33505 4.16677 3.66954 4.19442 4 4.24966M9 11.167H11.25C11.664 11.167 12 10.831 12 10.417V7.16699C12 4.19366 9.838 1.72633 7 1.24966C6.66954 1.19442 6.33505 1.16677 6 1.16699H4.75C4.336 1.16699 4 1.50299 4 1.91699V4.24966M9 11.167H4.75C4.55109 11.167 4.36032 11.088 4.21967 10.9473C4.07902 10.8067 4 10.6159 4 10.417V4.24966M12 8.66699V7.41699C12 6.82026 11.7629 6.24796 11.341 5.826C10.919 5.40405 10.3467 5.16699 9.75 5.16699H8.75C8.55109 5.16699 8.36032 5.08798 8.21967 4.94732C8.07902 4.80667 8 4.61591 8 4.41699V3.41699C8 3.12152 7.9418 2.82894 7.82873 2.55596C7.71566 2.28297 7.54992 2.03494 7.34099 1.826C7.13206 1.61707 6.88402 1.45134 6.61104 1.33826C6.33806 1.22519 6.04547 1.16699 5.75 1.16699H5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </Icon>
);
