import { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { useRouter } from 'next/router';
import { useQuestionsStore } from '@/stores';
import { Routes } from '@/constants';

interface PreviousPageLinkProps {
  text: string;
}

const PreviousPageLink: FC<PreviousPageLinkProps> = ({ text }) => {
  const { push } = useRouter();
  const { filterParams } = useQuestionsStore();

  const handleBack = () => {
    // @ts-ignore
    push({ pathname: Routes.Questions, query: filterParams });
  };

  return (
    <Flex
      alignItems="center"
      maxW="200px"
      flexShrink={0}
      onClick={handleBack}
      _hover={{ cursor: 'pointer', textDecoration: 'underline' }}
    >
      <ChevronLeftIcon fontSize="26px" />
      <Text ml="6px" fontSize="20px">
        {text}
      </Text>
    </Flex>
  );
};

export default PreviousPageLink;
