import React, { FC, useEffect, useMemo, useState } from 'react';
import Image from 'next/image';
import {
  Center,
  Flex,
  Grid,
  Heading,
  LinkProps,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { CopyIcon, LinkedInIcon, TwitterXIcon } from '@/components/common';
import { getSharingLinks, getSiteUrl } from '@/utils';
import { useUser } from '@/hooks';
import { TwitterCard } from '@/components/common/Modals/ShareModal/assets';
import { DEFAULT_SHARING_TEXT, Routes } from '@/constants';
import { Question as BaseQuestion } from '@/types/models';

interface ShareModalProps extends Partial<Pick<BaseQuestion, 'company' | 'category'>> {
  isModalOpen: boolean;
  onClose: () => void;
  isQuestionPage?: boolean;
}

interface ShareItemProps {
  icon: FC;
  label: string;
  labelSucceeded?: string;
  link: string;
  bgColor: string;
  isCopyButton?: true;
}

export const ShareModal: FC<ShareModalProps> = ({ isModalOpen, onClose, company, category, isQuestionPage }) => {
  const { asPath } = useRouter();
  const { user } = useUser();

  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const referralCode = useMemo(() => user?.referralCode, [user]);
  const url = getSiteUrl(asPath.split('?')[0]);
  const text = DEFAULT_SHARING_TEXT;
  const companyForHashtag = company?.split(',')[0] || '';
  const hashtags = ['DataLemur', category, companyForHashtag].filter(Boolean) as string[];

  const { twitterUrl, linkedInUrl } = getSharingLinks({ text, url, hashtags, referralCode });

  const SHARE_ITEMS: ShareItemProps[] = [
    { icon: LinkedInIcon, label: 'Share on LinkedIn', link: linkedInUrl, bgColor: '#007EBB1A' },
    { icon: TwitterXIcon, label: 'Share on X', link: twitterUrl, bgColor: '#0000001A' },
    {
      icon: CopyIcon,
      label: 'Copy link',
      labelSucceeded: 'Copied!',
      link: url,
      bgColor: 'gray.200',
      isCopyButton: true,
    },
  ];

  const linkProps: Partial<Pick<LinkProps, 'as' | 'target' | 'rel'>> = {
    as: 'a',
    target: '_blank',
    rel: 'noopener noreferrer',
  };

  const getModalTitle = () => {
    if (isQuestionPage) {
      return 'Share This Question';
    }

    if (asPath.includes(Routes.SQLTutorial)) {
      return 'Share This SQL Tutorial';
    }

    if (asPath.includes(Routes.Blog)) {
      return 'Share This Blog';
    }

    return 'Share DataLemur';
  };

  const onCopyToClipboard = async (msgText: string) => {
    await navigator.clipboard.writeText(msgText);
    setIsLinkCopied(true);
  };

  useEffect(() => {
    if (!isLinkCopied) {
      return;
    }

    const timer = setTimeout(() => {
      setIsLinkCopied(false);
    }, 2 * 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [isLinkCopied]);

  return (
    <Modal isCentered closeOnEsc closeOnOverlayClick onClose={onClose} isOpen={isModalOpen} motionPreset="scale">
      <ModalOverlay />
      <ModalContent p={0} maxW="720px" borderRadius="8px">
        <ModalCloseButton />
        <ModalBody p={0} display="flex" justifyContent="center" alignItems="center">
          <Grid templateColumns="1fr 1fr" width="100%" borderRadius="7px">
            <Flex flexDir="column" padding="16px" gap="10px">
              <Heading as="h3" fontWeight="600" fontSize="24px" lineHeight="32px" color="black.500">
                {getModalTitle()}
              </Heading>

              <Flex flexDir="column" gap="8px">
                {SHARE_ITEMS.map(({ label, labelSucceeded, link, icon, bgColor, isCopyButton }) => (
                  <Center
                    key={label}
                    fontWeight={600}
                    minH="100px"
                    bgColor={isCopyButton ? (isLinkCopied ? 'green.100' : bgColor) : bgColor}
                    transition="all 0.75s"
                    gap="8px"
                    cursor="pointer"
                    {...(isCopyButton ? { onClick: () => onCopyToClipboard(link) } : linkProps)}
                    href={link}
                  >
                    {icon({ mt: '2px', fontSize: '18px' })}
                    {isCopyButton && isLinkCopied ? labelSucceeded : label}
                  </Center>
                ))}
              </Flex>
            </Flex>

            <Flex
              width="100%"
              height="100%"
              bg="pink.200"
              alignItems="end"
              justifyContent="center"
              borderTopRightRadius="8px"
              borderBottomRightRadius="8px"
            >
              <Flex flexDir="column" gap="60px" alignItems="center" paddingBottom="16px">
                <Image src={TwitterCard.src} width={250} height={128} alt="" objectFit="none" />

                <Heading
                  gridRow="5/6"
                  as="h3"
                  maxW="242px"
                  fontWeight="600"
                  fontSize="16px"
                  lineHeight="24px"
                  color="red.900"
                >
                  Tag{' '}
                  <Text as="span" textDecoration="underline">
                    Nick Singh
                  </Text>{' '}
                  and he&apos;ll share your post with his 170k LinkedIn followers and 30k X followers!
                </Heading>
              </Flex>
            </Flex>
          </Grid>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
