import { Icon, IconProps } from '@chakra-ui/react';

export const CheckIcon = (props: IconProps): JSX.Element => (
  <Icon viewBox="0 0 13 12" fill="none" {...props}>
    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.5 6.5L5.5 10.5L11.5 1.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </Icon>
);
