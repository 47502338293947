import { FC } from 'react';
import {
  Button,
  Center,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { useRouter } from 'next/router';
import { Routes } from '@/constants';

interface LoginModalProps {
  isModalOpen: boolean;
  onClose: () => void;
}

const SubscribeModal: FC<LoginModalProps> = ({ isModalOpen = false, onClose }) => {
  const { push } = useRouter();
  const handleSubscribeClick = () => push(Routes.Pricing);

  return (
    <Modal isCentered onClose={onClose} isOpen={isModalOpen} motionPreset="scale">
      <ModalOverlay />
      <ModalContent minH="220px" bg="gray.100">
        <ModalCloseButton />
        <ModalBody display="flex" justifyContent="center" alignItems="center">
          <Center flexDirection="column">
            <Heading mb="10px" textAlign="center" fontWeight="500" fontSize="18px" color="black.300">
              Subscribe to unlock.
            </Heading>
            <Heading mb="30px" textAlign="center" fontWeight="500" fontSize="16px" color="gray.400">
              Thanks for using DataLemur! To view this question you must subscribe to premium.
            </Heading>
            <Button onClick={handleSubscribeClick} autoFocus colorScheme="red" rightIcon={<ChevronRightIcon />}>
              Subscribe
            </Button>
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SubscribeModal;
