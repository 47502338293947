import { Icon, IconProps } from '@chakra-ui/react';

export const SearchIcon = (props: IconProps): JSX.Element => (
  <Icon viewBox="0 0 14 14" fill="none" {...props}>
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13 13L9.53537 9.53531M9.53537 9.53531C10.4731 8.59759 10.9999 7.32577 10.9999 5.99964C10.9999 4.67351 10.4731 3.40169 9.53537 2.46397C8.59765 1.52625 7.32583 0.999451 5.9997 0.999451C4.67357 0.999451 3.40175 1.52625 2.46403 2.46397C1.52632 3.40169 0.999512 4.67351 0.999512 5.99964C0.999512 7.32577 1.52632 8.59759 2.46403 9.53531C3.40175 10.473 4.67357 10.9998 5.9997 10.9998C7.32583 10.9998 8.59765 10.473 9.53537 9.53531Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </Icon>
);
