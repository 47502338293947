import { FC } from 'react';
import { useRouter } from 'next/router';
import { Button, Center, Heading } from '@chakra-ui/react';
import { CheckIcon } from '@/assets/icons';
import { Routes } from '@/constants';

const SuccessesSubscribe: FC = () => {
  const { push } = useRouter();
  const handleStartPracticing = () => push(Routes.Questions);

  return (
    <Center flexDir="column">
      <CheckIcon />
      <Heading fontSize="28px" textAlign="center">
        Congratulations, you are now a premium user!
      </Heading>
      <Button onClick={handleStartPracticing} w="70%" minW="200px" h="60px" colorScheme="red" mt="20px">
        Start Practicing
      </Button>
    </Center>
  );
};

export default SuccessesSubscribe;
