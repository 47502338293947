import { Icon, IconProps } from '@chakra-ui/react';

export const ChevronRight = (props: IconProps): JSX.Element => (
  <Icon viewBox="0 0 8 12" fill="none" {...props}>
    <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.5 1L6.5 6L1.5 11"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </Icon>
);
