import { DateTime } from 'luxon';
import { QueryTableValueType } from '@/types/models';

const isValidDate = (value: QueryTableValueType) => {
  return typeof value === 'string' && Number.isNaN(Number(value)) && new Date(value).getTime() > 0;
};

const formatDate = (date: string) => {
  const format = date.length > 10 ? 'MM/dd/yyyy HH:mm:ss' : 'MM/dd/yyyy';
  const convertedDate = DateTime.fromISO(date, { zone: 'UTC' });

  return convertedDate.isValid ? convertedDate.toFormat(format) : date;
};

const formatObject = (value: QueryTableValueType) => {
  if (value === null) {
    return 'NULL';
  }

  if (!value) {
    return value;
  }

  return typeof value === 'object' ? JSON.stringify(value).slice(1, -1) : value;
};

export const processValue = (value: QueryTableValueType) => {
  return isValidDate(value) ? formatDate(value as string) : formatObject(value);
};

export const formatColumnTitle = (title: string) => (title === '?column?' ? 'column' : title.split(/__dup\d+__/)[0]);
