import { FC } from 'react';
import { ListItem, UnorderedList } from '@chakra-ui/react';
import { Testimonial, TestimonialOptions } from './components';
import styles from './Testimonials.module.scss';

const ITEMS: TestimonialOptions[] = [
  {
    companyLogoSrc: '/images/companies/logo-amazon.png',
    companyName: 'Amazon',
    text: 'I signed up for the DataLemur Beta in July when a post about it randomly came up on my LinkedIn feed.\n\nI’m so glad I came to know about DataLemur because a few months later when I interviewed at Amazon, not one but TWO of the SQL problems from DataLemur showed up during my onsite interview which I was easily able to ace!',
    author: 'Jia Z.',
    authorPosition: 'Senior Data Analyst at Amazon',
  },
  {
    companyLogoSrc: '/images/companies/logo-meta.png',
    companyName: 'Meta',
    text: 'In Fall 2021 I used the book Ace the Data Science Interview to land a Product Data Science job at Meta.\n\nAs I prep to switch jobs in early 2023, DataLemur is proving itself very handy because it allows me to interactively practice many of the SQL questions from the book, but also has tons of new Stats & ML interview questions that aren’t in the book',
    author: 'Rakesh',
    authorPosition: 'Data Scientist at Meta',
  },
  {
    companyLogoSrc: '/images/companies/logo-accenture.png',
    companyName: 'Accenture',
    text: 'I did Udemy SQL courses but they didn’t have enough interview practice which is why my NIT batchmate told me to try DataLemur.\n\nUsing the detailed hints and nice solutions on DataLemur I was able to crack interviews at Infosys and Accenture and get ₹4 lakh salary increase',
    author: 'Savita G.',
    authorPosition: 'BI Engineer at Accenture',
  },
];

const Testimonials: FC = () => {
  return (
    <UnorderedList className={styles.list} margin={0} listStyleType="none">
      {ITEMS.map((item, index) => (
        <ListItem key={index}>
          <Testimonial className={styles.testimonial} {...item} />
        </ListItem>
      ))}
    </UnorderedList>
  );
};

export default Testimonials;
