import { FC, ReactElement } from 'react';
import NextLink from 'next/link';
import { Button, LinkProps } from '@chakra-ui/react';

interface ShareButtonProps {
  className?: string;
  url?: string;
  icon: ReactElement;
  text: string;
  onClick?: () => void;
}

const ShareButton: FC<ShareButtonProps> = (props) => {
  const additionalProps: Partial<Pick<LinkProps, 'as' | 'target' | 'rel'>> = props.url
    ? {
        as: 'a',
        target: '_blank',
        rel: 'noopener noreferrer',
      }
    : {};
  const buttonEl = (
    <Button
      {...additionalProps}
      className={props.className}
      leftIcon={props.icon}
      fontSize="14px"
      variant="outline"
      height="32px"
      padding="0 12px"
      color="blue.400"
      borderColor="blue.400"
      borderRadius="20px"
      cursor="pointer"
      onClick={props.onClick}
    >
      {props.text}
    </Button>
  );

  return props.url ? (
    <NextLink href={props.url} passHref legacyBehavior>
      {buttonEl}
    </NextLink>
  ) : (
    buttonEl
  );
};

export default ShareButton;
