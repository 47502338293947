import React, { FC, useEffect, useState } from 'react';
import { Button, Flex } from '@chakra-ui/react';
import { CheckIcon, ClockIcon, CrossIcon, PauseIcon, RefreshIcon } from '@/components/common';

const TIMER_INITIAL_VALUE = 0;

export const Timer: FC = () => {
  const [seconds, setSeconds] = useState<number>(TIMER_INITIAL_VALUE);
  const [isRunning, setIsRunning] = useState<boolean>(false);

  useEffect(() => {
    if (!isRunning) {
      return;
    }

    const interval = setInterval(() => {
      setSeconds((prev) => prev + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [isRunning]);

  const formatTime = (): string => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    const formattedSecs = secs < 10 ? `0${secs}` : secs;

    return `${minutes}:${formattedSecs}`;
  };

  return (
    <Flex borderRadius="8px" border="1px solid" borderColor="gray.300" color="black.500">
      <Button
        minWidth="84px"
        variant="wrapper"
        lineHeight="24px"
        leftIcon={<ClockIcon />}
        borderRightRadius="none"
        justifyContent="start"
        cursor="default"
        color="inherit"
        _hover={{}}
      >
        {formatTime()}
      </Button>

      <Button
        borderRadius="none"
        variant="wrapper"
        borderLeft="1px solid"
        borderColor="inherit"
        color="inherit"
        onClick={() => setIsRunning((prev) => !prev)}
      >
        {isRunning ? <PauseIcon /> : <CheckIcon />}
      </Button>

      <Button
        borderRadius="none"
        variant="wrapper"
        borderLeft="1px solid"
        borderColor="inherit"
        color="inherit"
        onClick={() => setSeconds(TIMER_INITIAL_VALUE)}
      >
        {isRunning ? <RefreshIcon /> : <CrossIcon />}
      </Button>
    </Flex>
  );
};
