import { FC, useMemo } from 'react';
import { Flex, Heading } from '@chakra-ui/react';
import { MAP_QUESTION_CATEGORY_TO_TEXT } from '@/constants';
import { Question } from '@/types/models';

const QuestionHeader: FC<Pick<Question, 'title' | 'company' | 'category'>> = ({ title, company, category }) => {
  const subTitle = useMemo(() => {
    const companyName = company ? company.concat(' ') : '';

    return `${companyName}${MAP_QUESTION_CATEGORY_TO_TEXT[category]} Interview Question`;
  }, [category, company]);

  const h1Title = useMemo(() => `${title} ${subTitle}`, [subTitle, title]);

  return (
    <Flex flexDir="column" marginBottom="12px" flexShrink={0}>
      <Heading as="h1" display="none">
        {h1Title}
      </Heading>

      <Heading as="h2" fontWeight="bold" variant="main" fontSize="24px" lineHeight="32px">
        {title}
      </Heading>
      <Heading as="h2" color="black.200" fontWeight="400" variant="main" fontSize="16px" lineHeight="24px">
        {subTitle}
      </Heading>
    </Flex>
  );
};

export default QuestionHeader;
