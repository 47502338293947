import { FC } from 'react';
import { CodeProps } from 'react-markdown/lib/ast-to-react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialLight } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import { PracticeCard } from '@/components/pages/lesson';
import { Markdown } from '@/components/common';

const PRACTICE_CARD_TEMPLATE_STRING = 'language-practice-card';

const MarkdownCodeBlock: FC<CodeProps> = ({ node, inline, className, children, ...restProps }) => {
  const match = /language-(\w+)/.exec(className || '');

  if (match && match.input.startsWith(PRACTICE_CARD_TEMPLATE_STRING)) {
    return (
      <PracticeCard match={match}>
        <Markdown>{String(children).replace(/\n$/, '')}</Markdown>
      </PracticeCard>
    );
  }
  return !inline && match ? (
    // @ts-ignore
    <SyntaxHighlighter
      //@ts-ignore
      style={materialLight}
      codeTagProps={{
        style: {
          background: 'inherit',
        },
      }}
      language={match[1]}
      PreTag="div"
      {...restProps}
    >
      {String(children).replace(/\n$/, '')}
    </SyntaxHighlighter>
  ) : (
    <code className={className} {...restProps}>
      {children}
    </code>
  );
};

export default MarkdownCodeBlock;
