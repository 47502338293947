import { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const TwitterXIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 17 16" fill="none" {...props}>
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_106_902)">
        <path
          d="M14.6136 15.9732H2.37303C1.34516 15.9732 0.511963 15.14 0.511963 14.1121V1.87157C0.511963 0.843691 1.34516 0.010498 2.37303 0.010498H14.6134C15.6413 0.010498 16.4745 0.843691 16.4745 1.87157V14.1119C16.4747 15.14 15.6415 15.9732 14.6136 15.9732Z"
          fill="black"
        />
        <path
          d="M2.96405 2.7915L7.2544 8.52819L2.93701 13.1922H3.90876L7.68871 9.10871L10.7427 13.1922H14.0494L9.51753 7.13295L13.5362 2.7915H12.5644L9.0834 6.55225L6.27072 2.7915H2.96405ZM4.39305 3.5074H5.91212L12.6202 12.4765H11.1011L4.39305 3.5074Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_106_902">
          <rect width="16" height="16" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  </Icon>
);
