import { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const HistoryIcon: FC<IconProps> = (props: IconProps) => (
  <Icon viewBox="0 0 16 16" {...props}>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 5V9H11M2.19588 6.5C2.87655 3.937 5.24826 2 8 2C11.268 2 14 4.732 14 8C14 11.231 11.2628 14 8 14C5.60052 14 3.37158 12.5047 2.45673 10.2961M2.19588 6.5L1 3.5M2.19588 6.5L5.5 5.5"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </Icon>
);
