import { Icon, IconProps } from '@chakra-ui/react';

export const DotSeparatorIcon = (props: IconProps): JSX.Element => (
  <Icon viewBox="0 0 4 3" fill="none" {...props}>
    <svg width="4" height="3" viewBox="0 0 4 3" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 2.59375C1.67708 2.59375 1.40365 2.48438 1.17969 2.26562C0.960938 2.04167 0.851562 1.77083 0.851562 1.45312C0.851562 1.13542 0.960938 0.867188 1.17969 0.648438C1.40365 0.424479 1.67708 0.3125 2 0.3125C2.32812 0.3125 2.60156 0.424479 2.82031 0.648438C3.03906 0.867188 3.14844 1.13542 3.14844 1.45312C3.14844 1.77083 3.03906 2.04167 2.82031 2.26562C2.60156 2.48438 2.32812 2.59375 2 2.59375Z"
        fill="currentColor"
      />
    </svg>
  </Icon>
);
