import { FC, ReactNode } from 'react';
import { HStack, Image, Popover, PopoverArrow, PopoverContent, PopoverTrigger, Text, VStack } from '@chakra-ui/react';
import { DotSeparatorIcon } from '@/components/common';
import { getDifficultyColor } from '@/components/pages/questions/QuestionRowItem/helpers';
import { QuestionPreview } from '@/types/models';
import { getApiUrlPrefix } from '@/utils';
import { Difficulty, MAP_QUESTION_CATEGORY_TO_TEXT, QuestionCategory } from '@/constants';

interface PreviewQuestionPopoverProps {
  children: ReactNode;
  questionPreviewProps: QuestionPreview | null;
  linkType: 'Previous' | 'Next';
}

export const PreviewQuestionPopover: FC<PreviewQuestionPopoverProps> = ({
  children,
  questionPreviewProps,
  linkType,
}) => {
  if (questionPreviewProps) {
    const { title, category, difficulty, company, icon } = questionPreviewProps;

    return (
      <Popover trigger="hover" placement="bottom" variant="textHint">
        <PopoverTrigger>{children}</PopoverTrigger>

        <PopoverContent boxShadow="0 6px 14px rgba(0,0,0,.3)" border="1px solid" borderColor="gray.200">
          <PopoverArrow />

          <VStack padding="8px" alignItems="start">
            <Text color="black.200" fontSize="12px" fontWeight={500}>
              {linkType} {MAP_QUESTION_CATEGORY_TO_TEXT[category as QuestionCategory]} question
            </Text>

            <Text color="black.500" fontWeight={600}>
              {title}
            </Text>

            <HStack gap="5px" alignItems="center">
              {icon && (
                <Image src={getApiUrlPrefix(icon)} alt={`${company} icon`} width="16px" height="16px" mt="2px" />
              )}

              {company && (
                <Text color="black.500" fontWeight={500}>
                  {company}
                </Text>
              )}

              {(icon || company) && <DotSeparatorIcon color="black.300" fontSize="6px" mt="2px" />}

              <Text
                fontSize="16px"
                lineHeight="24px"
                fontWeight={600}
                color={getDifficultyColor(difficulty as Difficulty)}
              >
                {difficulty}
              </Text>
            </HStack>
          </VStack>
        </PopoverContent>
      </Popover>
    );
  }

  return children;
};
