import { FC } from 'react';
import { Alert, AlertTitle, Spinner } from '@chakra-ui/react';

interface LoadingToasterProps {
  title: string;
}

const LoadingToast: FC<LoadingToasterProps> = (props) => {
  const { title } = props;
  return (
    <Alert
      backgroundColor="gray.100"
      variant="top-accent"
      alignItems="center"
      borderRadius="md"
      boxShadow="lg"
      paddingEnd={8}
      mb="10px"
      textAlign="center"
      width="auto"
    >
      <Spinner marginRight="4" />
      <AlertTitle>{title}</AlertTitle>
    </Alert>
  );
};

export default LoadingToast;
