import { Dispatch, FC, SetStateAction } from 'react';
import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { MAP_QUESTION_CATEGORY_TO_TEXT, QuestionCategory, QuestionsFilterQueryKey } from '@/constants';
import { useQuestionsFilterQuery } from '@/hooks';
import { ChevronDownIcon, getButtonConditionalHoverProps, getButtonConditionalStyleProps } from '@/components/common';

interface MenuFilterCategoryProps {
  filterCategory: QuestionCategory | null;
  setFilterCategory: Dispatch<SetStateAction<QuestionCategory | null>>;
}

const MenuFilterCategory: FC<MenuFilterCategoryProps> = (props) => {
  const { filterCategory, setFilterCategory } = props;

  const { setFilterQuery } = useQuestionsFilterQuery();

  const setFilterCategoryHandler = async (category: QuestionCategory | null) => {
    await setFilterQuery(QuestionsFilterQueryKey.Category, category);
    setFilterCategory(category);
  };

  const menuButtonMinWidth = filterCategory === QuestionCategory.MachineLearning ? '176px' : '126px';

  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton
            as={Button}
            isActive={isOpen}
            iconSpacing="6px"
            rightIcon={<ChevronDownIcon color="inherit" />}
            minW={{ sm: menuButtonMinWidth }}
            width="100%"
            borderRadius="8px"
            border="1px solid"
            variant="wrapper"
            {...getButtonConditionalStyleProps(!!filterCategory)}
            _hover={getButtonConditionalHoverProps(!!filterCategory)}
          >
            {(filterCategory && MAP_QUESTION_CATEGORY_TO_TEXT[filterCategory]) || 'Category'}
          </MenuButton>
          <MenuList>
            <MenuItem onClick={() => setFilterCategoryHandler(null)}>All</MenuItem>
            <MenuItem onClick={() => setFilterCategoryHandler(QuestionCategory.SQL)}>
              {MAP_QUESTION_CATEGORY_TO_TEXT[QuestionCategory.SQL]}
            </MenuItem>
            <MenuItem onClick={() => setFilterCategoryHandler(QuestionCategory.Statistics)}>
              {MAP_QUESTION_CATEGORY_TO_TEXT[QuestionCategory.Statistics]}
            </MenuItem>
            <MenuItem onClick={() => setFilterCategoryHandler(QuestionCategory.MachineLearning)}>
              {MAP_QUESTION_CATEGORY_TO_TEXT[QuestionCategory.MachineLearning]}
            </MenuItem>
            <MenuItem onClick={() => setFilterCategoryHandler(QuestionCategory.Probability)}>
              {MAP_QUESTION_CATEGORY_TO_TEXT[QuestionCategory.Probability]}
            </MenuItem>
            <MenuItem onClick={() => setFilterCategoryHandler(QuestionCategory.Python)}>
              {MAP_QUESTION_CATEGORY_TO_TEXT[QuestionCategory.Python]}
            </MenuItem>
          </MenuList>
        </>
      )}
    </Menu>
  );
};

export default MenuFilterCategory;
