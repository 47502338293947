import { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';

interface ProductPlanPriceProps {
  currentPlanPrice: string;
  oldPlanPrice: string | null;
  subTitle: string | null;
}

const ProductPlanPrice: FC<ProductPlanPriceProps> = (props) => {
  const { currentPlanPrice, oldPlanPrice, subTitle } = props;

  return (
    <Flex>
      <Text fontSize="40px" fontWeight="700">
        {currentPlanPrice}
      </Text>
      <Flex ml="16px" flexDir="column" justifyContent="center" lineHeight="1">
        {oldPlanPrice && <Text textDecoration="line-through">{oldPlanPrice}</Text>}
        <Text fontSize={oldPlanPrice ? undefined : '20px'}>{subTitle}</Text>
      </Flex>
    </Flex>
  );
};

export default ProductPlanPrice;
