import { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const ChatSingleIcon: FC<IconProps> = (props: IconProps) => (
  <Icon viewBox="0 0 16 14" {...props}>
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.5 7.50667C1.5 8.57333 2.24867 9.50267 3.30467 9.658C4.01667 9.76267 4.73667 9.844 5.46333 9.90067C5.774 9.92533 6.05867 10.088 6.232 10.348L8 13L9.768 10.348C9.94133 10.088 10.226 9.92533 10.5367 9.90133C11.2633 9.844 11.9833 9.76267 12.6953 9.658C13.7513 9.50267 14.5 8.574 14.5 7.506V3.494C14.5 2.426 13.7513 1.49733 12.6953 1.342C11.1406 1.11381 9.57135 0.999507 8 1C6.40533 1 4.83733 1.11667 3.30467 1.342C2.24867 1.49733 1.5 2.42667 1.5 3.494V7.506V7.50667Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </Icon>
);
