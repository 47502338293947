import { create } from 'zustand';
import { TableOfContentItem } from '@/utils';

interface QuestionsStore {
  activeId: string | null;
  setActiveId: (activeId: string | null) => void;
  tableOfContentItems: TableOfContentItem[];
  setTableOfContentItems: (value: TableOfContentItem[]) => void;
  titleIds: string[];
  setTitleIds: (id: string, inView: boolean, position: number) => void;
}

export const useBlogStore = create<QuestionsStore>((set) => ({
  activeId: null,
  setActiveId: (activeId) => set({ activeId }),
  tableOfContentItems: [],
  setTableOfContentItems: (ToCItems) => {
    return set({ tableOfContentItems: ToCItems });
  },
  titleIds: [],
  setTitleIds: (id, inView, position) =>
    set((state) => {
      if (position < 100) {
        if (inView) {
          return { titleIds: [id, ...state.titleIds.filter((titleId) => titleId !== id)] };
        }

        const currentItemIdx = state.tableOfContentItems.findIndex(({ id: tocId }) => tocId === id);
        const previousItemIdx = state.tableOfContentItems.findIndex(({ id: tocId }) => tocId === state.titleIds[1]);

        if (state.titleIds[0] === id && previousItemIdx > currentItemIdx) {
          const sliced = state.titleIds.filter((itemId) => itemId !== id);

          return { titleIds: [sliced[0], id, ...sliced.slice(1)] };
        }

        return state;
      }
      if (position > 100) {
        const isFirstItem = state.tableOfContentItems[0].id === id;

        if (inView) {
          if (isFirstItem) {
            return { titleIds: [id] };
          }

          return { titleIds: [id, ...state.titleIds] };
        }

        if (isFirstItem) {
          return { titleIds: [] };
        }

        return { titleIds: state.titleIds.filter((titleId) => titleId !== id) };
      }

      return state;
    }),
}));
