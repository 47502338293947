import { FC, ReactNode } from 'react';
import { useUser } from '@/hooks';
import Loader from '../Loader';

const AuthInitializedOnly: FC<{ children: ReactNode }> = ({ children }) => {
  const { isAuthInitialized } = useUser();

  return isAuthInitialized ? <>{children}</> : <Loader />;
};

export default AuthInitializedOnly;
