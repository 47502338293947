import { FC } from 'react';
import { Button, HStack, StackProps, VStack } from '@chakra-ui/react';
import { MarkdownEditorCustom } from '@/components/common';

interface CommentMdEditorProps {
  mdValue: string;
  onChange: (mdValue?: string) => void;
  onCancel: () => void;
  onSubmit: () => void;
  isLoading: boolean;
  isSubmitDisabled?: boolean;
  styleProps?: StackProps;
}

const CommentMdEditor: FC<CommentMdEditorProps> = ({
  mdValue,
  onChange,
  onCancel,
  onSubmit,
  isLoading,
  isSubmitDisabled,
  ...styleProps
}) => (
  <VStack gap="0" width="100%" {...styleProps}>
    <MarkdownEditorCustom value={mdValue} onChange={onChange} />

    <HStack
      width="100%"
      padding="8px"
      gap="8px"
      border="1px solid"
      borderColor="gray.codeEditorBorder"
      borderTop="none"
      justifyContent="end"
      borderBottomRadius="4px"
    >
      <Button onClick={onCancel} variant="outlineCustom" isLoading={isLoading}>
        Cancel
      </Button>

      <Button
        isDisabled={!mdValue.trim().length || isSubmitDisabled}
        onClick={onSubmit}
        variant="primaryCustom"
        isLoading={isLoading}
        loadingText="Submitting..."
      >
        Comment
      </Button>
    </HStack>
  </VStack>
);

export default CommentMdEditor;
