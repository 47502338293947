import { FC, ReactNode } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Center,
  Heading,
  Text,
} from '@chakra-ui/react';
import Link from 'next/link';
import { ExternalLinks } from '@/constants';
import styles from '@/components/pages/home/AboutSection/AboutSection.module.scss';

export interface AccordionItemProps {
  title: string;
  children: ReactNode;
}

export const AccordionElement: FC<AccordionItemProps> = ({ title, children }) => {
  return (
    <AccordionItem border="none">
      <AccordionButton width="100%" justifyContent="space-between">
        <Heading as="h3" fontSize="18px" lineHeight="22px" fontWeight={700}>
          {title}
        </Heading>
        <AccordionIcon width="30px" height="30px" />
      </AccordionButton>
      <AccordionPanel display="flex" flexDir="column" gap="14px">
        {children}
      </AccordionPanel>
    </AccordionItem>
  );
};

export const Faq: FC = () => (
  <Center flexDir="column" gap="20px" my="40px">
    <Heading as="h2" fontSize="30px">
      Frequently Asked Questions
    </Heading>

    <Accordion allowMultiple display="flex" flexDir="column" gap="10px" width="100%" maxW="900px" border="none">
      <AccordionElement title="How does scheduling a 1:1 coaching with Nick Singh work?">
        <Text>
          After purchase of the coaching + book + life-time DataLemur access package, you’ll get a link to schedule a
          1-hour video call over Google Meets with Nick Singh. You can choose to book the coaching session whenever you
          want – even weeks, months, or a year into the future!
        </Text>
        <Text>
          The booking link includes quick background questions so that the call is tailored to your exact career needs.
        </Text>
      </AccordionElement>
      <AccordionElement title="What’s covered in a 1:1 coaching session with Nick?">
        <Text>
          Nick’s coached over 600 people to land Data Science, Data Analytics, and ML offers at Google, Amazon, Facebook
          and more. He’s previously worked at Facebook, Google, and a Series-B data startup SafeGraph, across roles in
          Data and Software Engineering. His career tips have earned him over 150,000 followers on LinkedIn.
        </Text>
        <Text>
          Typically, people get their resume and LinkedIn reviewed, get personalized cold-email networking tips, and
          work on their personal pitch. Some people opt for a more technical conversation, which could include a
          personalized SQL & Data Science study plan or mock technical interview with detailed feedback. It’s totally up
          to you!
        </Text>
      </AccordionElement>
      <AccordionElement title="How does shipping Ace the Data Science Interview work?">
        <Text>
          After purchase of the coaching + book + life-time DataLemur access package, you’ll fill out a quick form so
          that we can ship you a signed physical copy of{' '}
          <Link
            href={ExternalLinks.BookLink}
            className={styles.aboutParagraphLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            Ace the Data Science Interview
          </Link>{' '}
          to the right address. We’ll ship it to anywhere in the world – India, Indonesia, Israel – it’s no problem!
        </Text>
      </AccordionElement>
      <AccordionElement title="What’s in the bonus video course?">
        <Text>
          If you purchase the yearly or life-time packages, you’ll get free access to the video course{' '}
          <Link
            href={ExternalLinks.AceTheDataScienceLink}
            className={styles.aboutParagraphLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            Ace the Data Job Hunt
          </Link>
          . The course has 25+ videos & downloadable resources to aid you in your data job hunt, and typically sells for
          $25. The course has resume roasts, end-to-end portfolio project tips, cold email networking tactics, and mock
          behavioral interviews.
        </Text>
      </AccordionElement>
      <AccordionElement title="Is DataLemur worth the money?">
        <Text>
          The typical US Data Science job makes $120,000+, and salaries with RSUs at companies like Meta and Amazon can
          exceed $200,000.
        </Text>
        <Text>
          Investing just $15 a month on data interview prep so that you can switch jobs or break into the field more
          quickly should be a no-brainer!
        </Text>
      </AccordionElement>
    </Accordion>
  </Center>
);
