import { FC } from 'react';
import { Code, Flex, FlexProps, Text } from '@chakra-ui/react';

interface CodeErrorProps extends Omit<FlexProps, 'title'> {
  title?: string;
  errorMessage: string;
  hideError?: () => void;
  shouldHideError?: boolean;
}

const CodeError: FC<CodeErrorProps> = ({ title = 'Error', errorMessage, hideError, ...containerProps }) => (
  <Flex
    direction="column"
    {...containerProps}
    color="red.850"
    p="6px 8px"
    border="1px solid"
    borderColor="red.850"
    borderRadius="6px"
  >
    <Text fontSize="16px" fontWeight={600} lineHeight="24px">
      {title}
    </Text>

    <Code color="red.850" fontSize="16px" lineHeight="24px" paddingInlineStart={0}>
      {errorMessage}
    </Code>
  </Flex>
);

export default CodeError;
