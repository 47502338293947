import { FC } from 'react';
import { Flex } from '@chakra-ui/react';

interface MenuIconProps {
  isOpen: boolean;
  handleClick: () => void;
}

export const MenuIcon: FC<MenuIconProps> = ({ isOpen, handleClick }) => (
  <Flex
    display={{ base: 'flex', lg: 'none' }}
    width={{ base: '20px', md: '32px' }}
    height={{ base: '16px', md: '20px' }}
    position="relative"
    onClick={handleClick}
    _hover={{
      cursor: 'pointer',
    }}
  >
    <Flex
      position="absolute"
      top={isOpen ? { base: '7px', md: '9px' } : '0'}
      left="0"
      bg="black.500"
      width={{ base: '20px', md: '32px' }}
      height={{ base: '1px', md: '2px' }}
      borderRadius="20px"
      transition={isOpen ? 'top .3s, transform .2s .3s' : 'transform .2s, top .3s .2s'}
      transform={isOpen ? 'rotate(-45deg)' : 'rotate(0deg)'}
    />

    <Flex
      position="absolute"
      top={{ base: '7px', md: '9px' }}
      left="0"
      bg="black.500"
      width={{ base: '20px', md: '32px' }}
      height={{ base: '1px', md: '2px' }}
      borderRadius="20px"
      transition={isOpen ? 'top .3s, transform .2s .3s' : 'transform .2s, top .3s .2s'}
      transform={isOpen ? 'rotate(-45deg)' : 'none'}
    />

    <Flex
      position="absolute"
      top={isOpen ? { base: '7px', md: '9px' } : { base: '14px', md: '18px' }}
      left="0"
      bg="black.500"
      width={{ base: '20px', md: '32px' }}
      height={{ base: '1px', md: '2px' }}
      borderRadius="20px"
      transition={isOpen ? 'top .3s, transform .2s .3s' : 'transform .2s, top .3s .2s'}
      transform={isOpen ? 'rotate(45deg)' : 'none'}
    />
  </Flex>
);
