import { SVGProps } from 'react';

const SIZE = 200;

function clamp(value: number, min: number, max: number) {
  return Math.min(Math.max(value, min), max);
}

const ringProps: SVGProps<SVGCircleElement> = {
  cx: SIZE / 2,
  cy: SIZE / 2,
  strokeWidth: 16,
  strokeLinecap: 'round',
  fill: 'none',
  style: { transformOrigin: 'center', transition: 'all 1s ease' },
};

const trackProps = {
  opacity: 0.5,
  stroke: '#E5E5E5',
};

function Ring({
  id,
  percent,
  radius,
  colors,
}: {
  id: string;
  percent: number;
  radius: number;
  colors: [string, string];
}) {
  const circumference = 2 * Math.PI * radius;
  const progress = clamp(percent, 0.001, 100);
  const offset = circumference - (progress * circumference) / 100;
  const maskId = `${id}-mask-1`;

  const progress2 = progress - 50;
  const offset2 = circumference - (progress2 * circumference) / 100;
  const maskId2 = `${id}-mask-2`;

  return (
    <>
      {/* Track */}
      <circle r={radius} {...ringProps} {...trackProps} />

      {/* First 50% */}
      <mask id={maskId}>
        <circle
          r={radius}
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          stroke="white"
          transform="rotate(-90)"
          {...ringProps}
        />
      </mask>
      <foreignObject x="0" y="0" width={SIZE} height={SIZE} mask={`url(#${maskId})`}>
        <div
          style={{
            height: SIZE,
            width: SIZE,
            background: `conic-gradient(from -10deg, ${colors[0]}, ${colors[1]} 380deg)`,
          }}
        />
      </foreignObject>

      {/* Last 50% */}
      {progress2 > 0 && (
        <>
          <mask id={maskId2}>
            <circle
              r={radius}
              strokeDasharray={circumference}
              strokeDashoffset={offset2}
              stroke="white"
              transform="rotate(90)"
              {...ringProps}
            />
          </mask>
          <foreignObject x="0" y="0" width={SIZE} height={SIZE} mask={`url(#${maskId2})`}>
            <div
              style={{
                height: SIZE,
                width: SIZE,
                background: `conic-gradient(from 10deg, ${colors[0]}, ${colors[1]})`,
              }}
            />
          </foreignObject>
        </>
      )}
    </>
  );
}

const easyRadius = 92;
const mediumRadius = 72;
const hardRadius = 52;

export const ProgressRing = ({
  easy,
  medium,
  hard,
  percentage,
}: {
  easy: number;
  medium: number;
  hard: number;
  percentage: number;
}) => (
  <svg width={SIZE} height={SIZE} viewBox={`0 0 ${SIZE} ${SIZE}`}>
    <Ring id="easy" radius={easyRadius} percent={easy} colors={['#16A34A', '#3ECB72']} />
    <Ring id="medium" radius={mediumRadius} percent={medium} colors={['#D98506', '#FFAD2E']} />
    <Ring id="hard" radius={hardRadius} percent={hard} colors={['#DC3B26', '#FF634E']} />

    <text
      fill="black"
      stroke="white"
      paintOrder="stroke"
      strokeLinejoin="round"
      strokeWidth={5}
      fontSize={36}
      fontWeight="800"
      x={SIZE / 2}
      y={SIZE / 2}
      textAnchor="middle"
      dominantBaseline="central"
    >
      {percentage}%
    </text>
  </svg>
);
