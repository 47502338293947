import { FC } from 'react';
import Link from 'next/link';
import { Button, Flex, Text } from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { Routes } from '@/constants';
import { SidebarLesson } from '@/types/pages/lesson';
import styles from './NextPageLink.module.scss';

export interface NextLessonLink extends Partial<SidebarLesson> {
  isBlogLink?: boolean;
}

const NextPageLink: FC<NextLessonLink> = (props) => {
  const { slug, shortTitle, isBlogLink = false } = props;

  const linkHeaderText = `Next ${isBlogLink ? 'Post' : 'Lesson'}`;
  const nextPageHref = `${isBlogLink ? Routes.Blog : Routes.SQLTutorial}/${slug}`;

  return (
    <Link href={nextPageHref} className={styles.nextLessonWrapper}>
      <Flex flexDir="column" mr="10px">
        <Text color="gray.500" fontSize="12px" alignSelf="flex-end">
          {linkHeaderText}
        </Text>
        <Text fontWeight="500">{shortTitle}</Text>
      </Flex>
      <Button colorScheme="green" color="green" variant="outline" _hover={{}}>
        <ChevronRightIcon />
      </Button>
    </Link>
  );
};

export default NextPageLink;
