import { FC, useCallback, useState } from 'react';
import { Center, Flex, useDisclosure } from '@chakra-ui/react';
import { AuthInitializedOnly, Loader, LoginModal } from '@/components/common';
import { NewCommentInput } from '@/components/pages/question';
import { COMMENT_FIELDS } from '@/constants/pages/question';
import { useUser } from '@/hooks';
import { Vote } from '@/types/models';
import { Comment } from '@/types/pages/question';
import { CommentService, Logger } from '@/services';
import { RequestOptions } from '@/types/api';
import { CommentsFilter, QuestionCategory } from '@/constants';
import DiscussionComment from '../DiscussionComment';

export interface DiscussionTabProps {
  questionCategory: QuestionCategory;
  currentReplies: Comment[];
  comments: Comment[];
  myComments: Comment[];
  filteredComments: Comment[];
  commentsFilter: CommentsFilter;
  setCommentsFilter: (filter: CommentsFilter) => void;
  hasMoreComments: boolean;
  questionId: number;
  isLoading: boolean;
  votes: Vote[];
  onCommentAdd: (comment: Comment) => void;
  onCommentDelete: (commentId: string, parentId: string | null) => void;
  onVoteAdd: (newVote: Vote) => void;
  isLoadingComments: boolean;
}

const DiscussionTab: FC<DiscussionTabProps> = (props) => {
  const {
    currentReplies,
    comments,
    myComments,
    filteredComments,
    commentsFilter,
    setCommentsFilter,
    hasMoreComments,
    votes,
    questionId,
    questionCategory,
    isLoading,
    onCommentAdd,
    onCommentDelete,
    onVoteAdd,
    isLoadingComments,
  } = props;

  const { user } = useUser();
  const [isCommentSubmitting, setIsCommentSubmitting] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleSubmitComment = useCallback(
    async (commentValue: string, parentId?: string): Promise<void> => {
      if (!user) {
        onOpen();
        return;
      }
      setIsCommentSubmitting(true);

      try {
        const createCommentRequestOptions: RequestOptions = {
          fields: COMMENT_FIELDS,
        };
        const { comment: newCommentItem, vote: newVoteItem } = await CommentService.createVotedComment<Comment>(
          {
            questionId,
            body: commentValue,
            parentId: parentId || null,
          },
          createCommentRequestOptions,
        );

        newCommentItem.user = user;

        onVoteAdd(newVoteItem);
        onCommentAdd(newCommentItem);
      } catch (error) {
        Logger.error(error);
      } finally {
        setIsCommentSubmitting(false);
      }
    },
    [questionId, user, onVoteAdd, onCommentAdd, onOpen],
  );

  return (
    <Flex alignItems="center" direction="column" h="inherit" overflow="auto">
      <LoginModal isModalOpen={isOpen} onClose={onClose} />
      <Flex w="100%" maxW="1000px" direction="column">
        <NewCommentInput
          questionCategory={questionCategory}
          questionId={questionId}
          isLoading={isLoading}
          commentsFilter={commentsFilter}
          setCommentsFilter={setCommentsFilter}
          isCommentSubmitting={isCommentSubmitting}
          handleSubmitComment={handleSubmitComment}
        />
      </Flex>

      <AuthInitializedOnly>
        <Flex w="100%" maxW="1000px" direction="column" gap="32px">
          {filteredComments.map((comment) => (
            <DiscussionComment
              key={comment.id}
              replyComments={currentReplies.filter((reply) => reply.parentId === comment.id)}
              userVote={votes.find((vote) => vote.userId === user?.id && vote.commentId === comment.id) || null}
              onCommentDelete={onCommentDelete}
              handleSubmitComment={handleSubmitComment}
              {...comment}
            />
          ))}
          {comments.length === 0 && !hasMoreComments && myComments.length === 0 && !isLoadingComments && (
            <Center w="100%" backgroundColor="gray.200" p="16px" my="10px">
              Nobody has commented yet! Start the discussion — what’s your take on the solution?
            </Center>
          )}
          {isLoadingComments && <Loader />}
        </Flex>
      </AuthInitializedOnly>
    </Flex>
  );
};
export default DiscussionTab;
