import { Dispatch, FC, SetStateAction, useCallback } from 'react';
import { Center, Flex, Heading, Text } from '@chakra-ui/react';
import { Tag } from '@/types/models';
import { getButtonConditionalHoverProps, getButtonConditionalStyleProps } from 'components/common/utils';

interface TagForDisplay extends Tag {
  questionsAmount: number;
}

interface TagsSectionProps {
  tags: TagForDisplay[];
  selectedTagIds: string[];
  setSelectedTagIds: Dispatch<SetStateAction<string[]>>;
}

const TagsSection: FC<TagsSectionProps> = (props) => {
  const { tags, setSelectedTagIds, selectedTagIds } = props;

  const handleTagSelect = useCallback(
    (tagId: string): void => {
      setSelectedTagIds((prevState) => {
        if (prevState.includes(tagId)) {
          return prevState.filter((id) => id !== tagId);
        }
        return [...prevState, tagId];
      });
    },
    [setSelectedTagIds],
  );

  return (
    <Center flexDir="column" w="350px" mt="20px" gap="12px">
      <Heading as="h3" fontWeight={600} fontSize="24px" alignSelf="baseline" variant="secondary">
        Tags
      </Heading>

      <Flex width="100%" flexDir="row" flexWrap="wrap" gap="8px">
        {tags.map(({ id, title, questionsAmount }) => {
          const isSelected = selectedTagIds?.includes(id);

          return (
            <Flex
              key={id}
              color="black.400"
              fontSize="12px"
              gap="6px"
              padding="6px 8px"
              border="1px solid"
              borderRadius="6px"
              cursor="pointer"
              onClick={() => handleTagSelect(id)}
              {...getButtonConditionalStyleProps(isSelected)}
              _hover={{ ...getButtonConditionalHoverProps(isSelected), fontWeight: 500 }}
              fontWeight={500}
            >
              <Text color="inherit">{title}</Text>

              <Text color="black.300">{questionsAmount}</Text>
            </Flex>
          );
        })}
      </Flex>
    </Center>
  );
};

export default TagsSection;
