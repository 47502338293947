import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Wrap,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { CommentsFilter, QuestionCategory } from '@/constants';
import { debounce, getDefaultCommentText } from '@/utils';
import { ChatSingleIcon } from '@/components/common';
import { CommentMdEditor } from '@/components/pages/question';

interface NewCommentInputProps {
  questionCategory: QuestionCategory;
  isCommentSubmitting: boolean;
  questionId: number;
  commentsFilter: CommentsFilter;
  setCommentsFilter: (value: CommentsFilter) => void;
  isLoading: boolean;
  handleSubmitComment: (commentValue: string, parentId?: string) => void;
}

const NewCommentInput: FC<NewCommentInputProps> = (props) => {
  const {
    questionCategory,
    isCommentSubmitting,
    questionId,
    commentsFilter,
    setCommentsFilter,
    isLoading,
    handleSubmitComment,
  } = props;
  const defaultCommentText = useMemo(() => getDefaultCommentText(questionCategory), [questionCategory]);
  const [mdValue, setMdValue] = useState<string>(defaultCommentText);
  const [isEditorCollapsed, setIsEditorCollapsed] = useState(true);
  const commentValueCacheKey = `comment-input-${questionId}`;

  const saveCommentValueToCache = useMemo(
    () =>
      debounce((value: string) => {
        localStorage.setItem(commentValueCacheKey, value);
      }, 1000),
    [commentValueCacheKey],
  );

  const handleCommentInputChange = useCallback(
    (newVal?: string): void => {
      if (typeof newVal === 'string') {
        setMdValue(newVal);
        saveCommentValueToCache(newVal);
      }
    },
    [saveCommentValueToCache],
  );

  const handleSubmit = useCallback(async () => {
    await handleSubmitComment(mdValue);

    setMdValue(defaultCommentText);
    localStorage.removeItem(commentValueCacheKey);
  }, [mdValue, commentValueCacheKey, defaultCommentText, handleSubmitComment]);

  useEffect(() => {
    const savedCommentInput = localStorage.getItem(commentValueCacheKey);

    if (savedCommentInput) {
      setMdValue(savedCommentInput);
    }
  }, [setMdValue, commentValueCacheKey]);

  const handleCollapseCommentInput = () => (isEditorCollapsed ? setIsEditorCollapsed((prev) => !prev) : undefined);

  const handleCancelComment = () => setIsEditorCollapsed(true);

  return (
    <>
      <Wrap pt="5px" w="100%" onClick={handleCollapseCommentInput} cursor={isEditorCollapsed ? 'pointer' : 'unset'}>
        {isEditorCollapsed ? (
          <InputGroup color="black.300" cursor="pointer">
            <InputLeftElement>
              <ChatSingleIcon color="inherit" />
            </InputLeftElement>

            <Input placeholder="Add a comment" />
          </InputGroup>
        ) : (
          <CommentMdEditor
            mdValue={mdValue}
            onSubmit={handleSubmit}
            onCancel={handleCancelComment}
            onChange={handleCommentInputChange}
            isLoading={isLoading}
          />
        )}
      </Wrap>
      <Flex
        flexDir={{ base: 'column-reverse', sm: 'row' }}
        mt="20px"
        mb={{ base: '20px', md: 0 }}
        gap="8px"
        justifyContent="end"
        alignItems="center"
      >
        <Text color="black.200" display={{ base: 'none', md: 'inline' }}>
          Sort:
        </Text>

        <Menu>
          {({ isOpen: isMenuOpen }) => (
            <>
              <MenuButton
                bg="gray.200"
                mt={{ base: '10px', sm: '0' }}
                w={{ base: '100%', sm: '200px' }}
                py="6px"
                _hover={{ bg: 'gray.300', _active: { bg: 'gray.400' } }}
                isActive={isMenuOpen}
                as={Button}
                rightIcon={<ChevronDownIcon />}
                isDisabled={isCommentSubmitting}
              >
                {commentsFilter}
              </MenuButton>
              <MenuList>
                <MenuItem onClick={() => setCommentsFilter(CommentsFilter.MostVotes)}>
                  {CommentsFilter.MostVotes}
                </MenuItem>
                <MenuItem onClick={() => setCommentsFilter(CommentsFilter.NewestToOldest)}>
                  {CommentsFilter.NewestToOldest}
                </MenuItem>
                <MenuItem onClick={() => setCommentsFilter(CommentsFilter.OldestToNewest)}>
                  {CommentsFilter.OldestToNewest}
                </MenuItem>
              </MenuList>
            </>
          )}
        </Menu>
      </Flex>
    </>
  );
};

export default NewCommentInput;
