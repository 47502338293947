import { FC, memo } from 'react';
import { Th, Thead, Tr } from '@chakra-ui/react';
import { useUser } from '@/hooks';

interface TableHeadProps {
  isFullTable?: boolean;
  isCommonQuestionsPage?: boolean;
  isGeneralAssemblyMvp?: boolean;
}

const QuestionsTableHead: FC<TableHeadProps> = (props) => {
  const { isFullTable = true, isCommonQuestionsPage, isGeneralAssemblyMvp } = props;
  const { user } = useUser();

  return (
    <Thead>
      <Tr>
        {!isGeneralAssemblyMvp && (
          <Th color="black.300" px={{ base: '12px', md: '24px' }}>
            Company
          </Th>
        )}
        <Th color="black.300" px={{ base: '12px', md: '24px' }}>
          Title
        </Th>
        {isCommonQuestionsPage && (
          <Th color="black.300" px={{ base: '12px', md: '24px' }}>
            Category
          </Th>
        )}
        <Th color="black.300" px={{ base: '12px', md: '24px' }}>
          Difficulty
        </Th>
        {isFullTable && user && (
          <Th color="black.300" textAlign="center" px={{ base: '12px', md: '24px' }}>
            Status
          </Th>
        )}
      </Tr>
    </Thead>
  );
};

export default memo(QuestionsTableHead);
