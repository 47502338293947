import { FC } from 'react';

const StarIcon: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="44px" height="44px" viewBox="0 0 512.01 512.01">
    <path
      d="M497.998,249.932c27.473-26.88,12.268-73.626-25.795-79.148L350.606,157.84c-0.406-0.076-1.449-0.834-1.989-1.933
			L297.134,27.288c-8.4-17.11-23.474-26.427-40.123-27.218c-18.701,0.79-33.789,10.116-41.481,25.724l-52.805,131.585
			c0.119-0.386-0.942,0.384-2.186,0.566L40.58,170.682c-38.894,5.627-54.094,52.428-26.552,79.299l88.211,87.061
			c0.97,0.949,1.379,2.211,1.165,3.467L83.335,457.56c-6.512,37.928,33.312,66.858,67.375,48.939l105.146-55.271
			c0.211-0.033,105.415,55.274,105.415,55.274c34.076,17.906,73.867-11,67.394-48.925l-20.099-117.098
			c-0.208-1.221,0.197-2.467,1.098-3.344L497.998,249.932z M366.509,347.667l20.101,117.108c0.524,3.072-2.709,5.42-5.488,3.959
			l-105.126-55.272c-5.817-3.045-12.04-4.648-18.552-5.09l-2.882-0.001c-6.549,0.442-12.79,2.049-18.579,5.1l-105.132,55.264
			c-2.764,1.454-5.991-0.89-5.464-3.96l20.075-117.082c2.565-15.075-2.407-30.409-13.318-41.084l-88.232-87.081
			c-2.33-2.273-1.096-6.073,1.987-6.52l119.982-12.741c15.944-2.315,28.998-11.797,35.771-25.5L254.467,43.16
			c-0.036,0.216,0.956-0.397,2.541-0.472c-0.449,0.075,0.56,0.698,1.206,2.003l51.454,128.56
			c7.401,15.2,20.468,24.699,35.596,26.911l121.62,12.948c2.275,0.346,3.509,4.145,1.267,6.333l-88.367,87.235
			C368.932,317.255,363.945,332.601,366.509,347.667z"
    />
  </svg>
);

export default StarIcon;
