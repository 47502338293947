import { StyleProps } from '@chakra-ui/react';

export const getButtonConditionalHoverProps = (isActive: boolean): StyleProps =>
  isActive
    ? { color: 'black.500', fontWeight: 600, bg: 'pink.200', borderColor: 'pink.200' }
    : { color: 'black.500', fontWeight: 400, bg: 'gray.200' };

export const getButtonConditionalStyleProps = (isActive: boolean): StyleProps =>
  isActive
    ? { color: 'black.500', fontWeight: 600, bg: 'pink.150', borderColor: 'pink.200' }
    : { fontWeight: 400, borderColor: 'gray.300', background: 'white' };
