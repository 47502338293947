import { FC } from 'react';

const ClearFilterIcon: FC = () => {
  return (
    <svg height="24px" id="icon" viewBox="0 0 32 32" width="24px" xmlns="http://www.w3.org/2000/svg">
      <polygon points="30 11.414 28.586 10 24 14.586 19.414 10 18 11.414 22.586 16 18 20.585 19.415 22 24 17.414 28.587 22 30 20.587 25.414 16 30 11.414" />
      <path d="M4,4A2,2,0,0,0,2,6V9.1709a2,2,0,0,0,.5859,1.4145L10,18v8a2,2,0,0,0,2,2h4a2,2,0,0,0,2-2V24H16v2H12V17.1709l-.5859-.5855L4,9.1709V6H24V8h2V6a2,2,0,0,0-2-2Z" />
    </svg>
  );
};

export default ClearFilterIcon;
