import React, { FC } from 'react';
import {
  Button,
  Center,
  Flex,
  Heading,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { ExternalLinks } from '@/constants';

interface CancelSubscriptionConfirmationModalProps {
  subscriptionId: string | undefined;
  userEmail: string | undefined;
  isModalOpen: boolean;
  markSubscriptionForCancellation: () => void;
  closeConfirmationModal: () => void;
}

const CancelSubscriptionConfirmationModal: FC<CancelSubscriptionConfirmationModalProps> = ({
  subscriptionId,
  userEmail,
  isModalOpen = false,
  markSubscriptionForCancellation,
  closeConfirmationModal,
}) => {
  return (
    <Modal
      isCentered
      closeOnEsc
      closeOnOverlayClick
      onClose={closeConfirmationModal}
      isOpen={isModalOpen}
      motionPreset="scale"
    >
      <ModalOverlay />
      <ModalContent minH="200px" bg="gray.100">
        <ModalCloseButton />
        <ModalBody display="flex" justifyContent="center" alignItems="center">
          <Center flexDirection="column" justifyContent="flex-end">
            <Heading mb="10px" textAlign="center" fontWeight="500" fontSize="18px" color="black.300">
              Premium subscription cancellation
            </Heading>
            <Text marginY="20px">Are you sure you want to cancel your subscription?</Text>
            <Flex gap="30px">
              <Button maxH="32px" autoFocus colorScheme="red" onClick={closeConfirmationModal}>
                No
              </Button>
              <Link
                href={ExternalLinks.FormToCancelSubscription.replace('{email}', userEmail || '').replace(
                  '{subscription-id}',
                  subscriptionId || '',
                )}
                target="_blank"
                rel="noopener noreferrer"
                onClick={markSubscriptionForCancellation}
              >
                Yes, cancel
              </Link>
            </Flex>
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CancelSubscriptionConfirmationModal;
