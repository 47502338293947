import React, { FC, ReactNode } from 'react';
import { Tab, TabProps } from '@chakra-ui/react';

interface TabTitleProps extends TabProps {
  icon: ReactNode;
  title: string;
}

export const TabTitle: FC<TabTitleProps> = ({ icon, title, ...restProps }) => {
  return (
    <Tab {...restProps}>
      {icon}
      {title}
    </Tab>
  );
};
