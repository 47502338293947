import { FC, ReactNode, useEffect } from 'react';
import { useRouter } from 'next/router';
import { NextSeo } from 'next-seo';
import classNames from 'classnames';
import { Box, Container, Flex } from '@chakra-ui/react';
import { SITE_URL } from '@/constants';
import { getSiteUrl } from '@/utils';
import { Banner, Footer, Header, HeaderProps } from '@/components/common';
import { useUser } from '@/hooks';
import { Logger, UserService } from '@/services';
import { useVerificationStore } from '@/stores';
import styles from './Layout.module.scss';

interface LayoutProps extends HeaderProps {
  className?: string;
  contentClassName?: string;
  tabTitle?: string;
  ogTitle?: string;
  ogImage?: string | null;
  tabDescription?: string;
  children: ReactNode;
  isShareButtonVisible?: boolean;
  isFooterHidden?: boolean;
  isQuestionsPage?: boolean;
}

const DEFAULT_TITLE = 'DataLemur - Ace the SQL & Data Science Interview';
const DEFAULT_DESCRIPTION =
  'Practice SQL Interview Questions & Data Science Interview Questions asked by FAANG. Made by Nick Singh, Best-Selling Author of Ace the Data Science Interview.';

export const QuestionListLayout: FC<LayoutProps> = (props) => {
  const {
    tabDescription,
    className,
    contentClassName,
    ogTitle,
    ogImage,
    tabTitle,
    isGeneralAssemblyMvp,
    children,
    isShareButtonVisible,
    isFooterHidden,
    isQuestionsPage,
    ...restQuestionData
  } = props;

  const { user } = useUser();
  const { asPath } = useRouter();
  const currentUrl = getSiteUrl(asPath);
  const {
    isVerificationCheckLoading,
    isVerificationCheckDone,
    shouldAskToVerify,
    setIsVerificationCheckLoading,
    setIsVerificationCheckDone,
    askToVerify,
    clearAskToVerify,
  } = useVerificationStore();
  const title = ogTitle || tabTitle || DEFAULT_TITLE;
  const description = tabDescription || DEFAULT_DESCRIPTION;
  const imageLink = ogImage || `${SITE_URL}/og_image.webp`;

  useEffect(() => {
    if (!user || user.emailVerified || isVerificationCheckLoading || isVerificationCheckDone) {
      return;
    }

    (async () => {
      try {
        setIsVerificationCheckLoading(true);

        const { isEmailVerified } = await UserService.checkIsEmailVerified();

        if (!isEmailVerified) {
          askToVerify();
        }
      } catch (error) {
        Logger.error(error);
      } finally {
        setIsVerificationCheckDone(true);
        setIsVerificationCheckLoading(false);
      }
    })();
  }, [
    user,
    isVerificationCheckLoading,
    isVerificationCheckDone,
    askToVerify,
    setIsVerificationCheckDone,
    setIsVerificationCheckLoading,
  ]);

  return (
    <Container
      className={classNames(styles.layout, className)}
      maxW="100%"
      w="100%"
      h="100%"
      m="0"
      p="0"
      flexDir="column"
      color="black.100"
      bg="gray.100"
      alignItems={{ lg: 'center' }}
    >
      <NextSeo
        title={title}
        description={description}
        canonical={currentUrl}
        openGraph={{
          url: currentUrl,
          title: title,
          description,
          type: 'website',
          images: [
            {
              url: imageLink,
              width: 1200,
              height: 630,
              alt: DEFAULT_TITLE,
            },
          ],
        }}
        twitter={{
          handle: '@nicksinghtech',
          site: '@nicksinghtech',
          cardType: 'summary_large_image',
        }}
        additionalMetaTags={[
          {
            name: 'twitter:image',
            content: imageLink,
          },
        ]}
      />
      <Header isGeneralAssemblyMvp={isGeneralAssemblyMvp} isQuestionPage={isShareButtonVisible} {...restQuestionData} />
      {shouldAskToVerify && <Banner isBannerVisible={shouldAskToVerify} hideBanner={clearAskToVerify} />}
      <Flex flexDir="column" width="100%" height="100%" maxW={{ base: '1400px', extraLarge: '1512px' }}>
        <Box
          className={classNames(styles.content, contentClassName, {
            [styles.contentWithBanner]: shouldAskToVerify,
          })}
          flex="1 0 auto"
        >
          {children}
        </Box>
        {!isFooterHidden && <Footer />}
      </Flex>
    </Container>
  );
};
