import React, {
  Dispatch,
  FC,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import dynamic from 'next/dynamic';
import { Ace } from 'ace-builds';
import { Flex, HStack, Text } from '@chakra-ui/react';
import { CodeEditorControls, CompanyInfo, MenuQuestionRuntimes } from '@/components/pages/question';
import { Loader } from '@/components/common';
import { ExpectedPythonOutput, SubmitQueryResult } from '@/types/models';
import { ActiveCampaignService, Logger, SubmitQueryService } from '@/services';
import {
  GeneralAssemblyQuestionTab,
  MixpanelEvent,
  QuestionStatus,
  StatusCode,
  SupportedRuntime,
  Tab,
} from '@/constants';
import { Operations } from '@/constants/pages';
import { Question, Submission } from '@/types/pages/question';
import { debounce } from '@/utils';
import { LoadingState } from '@/types';
import { useRunQueryStore } from '@/stores';
import { MPTracker } from '@/services/analytics';
import { getFuncNameFromPythonQuery } from './utils';

const PYTHON_TIMEOUT_ERROR_DELAY = 5 * 1000;
const PYTHON_FUNC_NAME_ERROR = 'NameError: function name is invalid';
const PYTHON_TIMEOUT_ERROR =
  'Timeout error: This code is taking too long to execute. Please correct your entry and try again.';

type CutQuestionType = Pick<
  Question,
  | 'pythonFunctionQuery'
  | 'pythonTestCases'
  | 'category'
  | 'difficulty'
  | 'company'
  | 'slug'
  | 'accessGroups'
  | 'companyInfo'
  | 'supportedRuntimes'
>;

interface CodeEditorTabProps extends CutQuestionType {
  questionId: number;
  baseQuery: string;
  codeEditorClassName: string;
  setSubmittedQueryResult: (result: SubmitQueryResult | null) => void;
  currentOperation: Operations;
  setCurrentOperation: Dispatch<SetStateAction<Operations>>;
  setTabIndex: (tabIndex: Tab) => void;
  setIsSubmittedTableLoading: (isSubmitTableLoading: boolean) => void;
  setSubmittedQueryError: (error: string | null) => void;
  setSubmissionsState: Dispatch<SetStateAction<LoadingState<Submission[]>>>;
  isMobile: boolean;
  isGeneralAssemblyMvp?: boolean;
  isPythonQuestion?: boolean;
}

const DynamicCodeEditor = dynamic(() => import('@/components/pages/question/CodeEditor'), {
  ssr: false,
  loading: () => <Loader height="500px" />,
});

const CodeEditorTab: FC<CodeEditorTabProps> = (props) => {
  const {
    questionId,
    baseQuery,
    currentOperation,
    codeEditorClassName,
    setSubmittedQueryResult,
    setCurrentOperation,
    setTabIndex,
    setIsSubmittedTableLoading,
    setSubmittedQueryError,
    setSubmissionsState,
    isMobile,
    isGeneralAssemblyMvp,
    isPythonQuestion,
    pythonFunctionQuery,
    pythonTestCases,
    category,
    difficulty,
    company,
    accessGroups,
    companyInfo,
    supportedRuntimes,
  } = props;

  const {
    pythonExpectedCasesExecuted,
    setPythonExpectedCasesExecuted,
    tableResult,
    setResultTable: setRunResult,
    expectedPythonOutput,
    setItemToExpectedPythonOutput,
    resetExpectedPythonOutput,
    isRunOutputTableVisible,
    setIsRunOutputTableVisible,
    isOutputTableLoading,
    setIsOutputTableLoading,
    outputTableError,
    setOutputTableError,
  } = useRunQueryStore();

  const mixPanelData = useMemo(
    () => ({
      category,
      difficulty,
      company,
      accessGroups: accessGroups?.join(' ,'),
    }),
    [accessGroups, category, company, difficulty],
  );

  const pythonWorker: MutableRefObject<Worker | null> = useRef<Worker>(null);
  const pythonRunnerTimer: MutableRefObject<NodeJS.Timeout | undefined> = useRef();

  const [editorValue, setEditorValue] = useState<string>(baseQuery);
  const editorValueCacheKey = `codeInput-${questionId}`;

  const [currentRuntime, setCurrentRuntime] = useState<SupportedRuntime>(SupportedRuntime.Postgres);
  const [isPythonExecuting, setIsPythonExecuting] = useState(false);
  const [isPythonRunnerReady, setIsPythonRunnerReady] = useState(false);
  const [pythonExecError, setPythonExecError] = useState<null | string>(null);
  const [errorLineNumbers, setErrorLineNumbers] = useState<Ace.Annotation[]>([]);

  const isPythonCasesTabVisible =
    Boolean(expectedPythonOutput.length) && !pythonExecError && isPythonQuestion && isRunOutputTableVisible;

  const isRunLoading = isPythonQuestion
    ? !isPythonRunnerReady || isPythonExecuting
    : currentOperation === Operations.Run;
  const isSubmitLoading = isPythonQuestion
    ? !isPythonRunnerReady || currentOperation === Operations.Submit || isPythonExecuting
    : currentOperation === Operations.Submit;

  useEffect(() => {
    if (!isPythonQuestion || pythonWorker.current || isPythonRunnerReady) {
      return;
    }
    pythonWorker.current = new Worker(new URL('./pyWorker.ts', import.meta.url));

    if (pythonRunnerTimer.current) {
      clearTimeout(pythonRunnerTimer.current);
    }

    pythonWorker.current.onmessage = ({
      data,
    }: MessageEvent<
      {
        isPythonRunnerReady?: boolean;
        error?: { message: string; annotations: Ace.Annotation[] };
      } & ExpectedPythonOutput
    >) => {
      if (data.isPythonRunnerReady) {
        return setIsPythonRunnerReady(true);
      }

      if (data.error) {
        setPythonExecError(data.error.message);
        setErrorLineNumbers(data.error.annotations || []);
      }

      setItemToExpectedPythonOutput(data);
      setCurrentOperation((prevState) => {
        if (prevState === Operations.Run) {
          setIsRunOutputTableVisible(true);
          setCurrentOperation(Operations.Watch);
        }
        return prevState;
      });

      setIsPythonExecuting(false);
      clearTimeout(pythonRunnerTimer.current);
    };
  }, [
    currentOperation,
    isPythonQuestion,
    isPythonRunnerReady,
    setCurrentOperation,
    setIsPythonRunnerReady,
    setIsRunOutputTableVisible,
    setItemToExpectedPythonOutput,
  ]);

  useEffect(() => {
    return () => {
      setPythonExpectedCasesExecuted(false);
    };
  }, [setPythonExpectedCasesExecuted]);

  useEffect(() => {
    if (!isPythonQuestion || pythonExpectedCasesExecuted || !pythonTestCases) {
      return;
    }

    if (!isPythonRunnerReady) {
      return;
    }

    setPythonExpectedCasesExecuted(true);
    resetExpectedPythonOutput();
    pythonTestCases.map(async (testQuery, index) => {
      try {
        const runPythonTestCaseQuery = `${testQuery.funcName}(${testQuery.args.map(({ value }) => value)})`;
        const testCaseQuery = `${pythonFunctionQuery}${runPythonTestCaseQuery}`;

        pythonWorker.current?.postMessage({ pythonQuery: testCaseQuery, index: index + 1, testQuery });
      } catch (error: any) {
        setPythonExecError(error.type);
      }
    });
  }, [
    isPythonQuestion,
    isPythonRunnerReady,
    pythonExpectedCasesExecuted,
    expectedPythonOutput,
    setItemToExpectedPythonOutput,
    pythonTestCases,
    pythonFunctionQuery,
    resetExpectedPythonOutput,
    setPythonExpectedCasesExecuted,
  ]);

  useEffect(() => {
    if (!isPythonQuestion || currentOperation !== Operations.Submit || isPythonExecuting) {
      return;
    }

    if (expectedPythonOutput.length !== pythonTestCases?.length) {
      return;
    }
    const isAllCasesSolved = !Boolean(expectedPythonOutput.filter((value) => !value?.isValid).length);
    const isOneTestPassed = expectedPythonOutput.find((value) => value?.isValid);
    (async () => {
      try {
        const status = isAllCasesSolved
          ? QuestionStatus.Solved
          : isOneTestPassed
          ? QuestionStatus.Mismatched
          : QuestionStatus.Wrong;
        const submission = await SubmitQueryService.submitPythonQuery(questionId, editorValue, status);

        setSubmittedQueryResult({
          submission,
          status: submission.status,
          payload: { adminQueryResult: [], userQueryResult: [], isPythonQuestion, pythonCases: expectedPythonOutput },
        });
        setSubmittedQueryError(null);
        setSubmissionsState((prevState) => ({
          ...prevState,
          data: [submission, ...prevState.data],
        }));
      } catch (e) {
        Logger.error(e);
      } finally {
        setCurrentOperation(Operations.DisabledAll);
        setIsSubmittedTableLoading(false);
      }
    })();
  }, [
    currentOperation,
    editorValue,
    expectedPythonOutput,
    isPythonExecuting,
    isPythonQuestion,
    pythonTestCases?.length,
    questionId,
    setCurrentOperation,
    setIsSubmittedTableLoading,
    setSubmissionsState,
    setSubmittedQueryResult,
    setSubmittedQueryError,
  ]);

  const runPythonCode = useCallback(
    (isSubmitTriggeredFunction: boolean = false) => {
      const defaultFuncName = expectedPythonOutput[0]?.testQuery.funcName;
      const userFuncName = getFuncNameFromPythonQuery(editorValue);

      if (!userFuncName && !editorValue.includes(defaultFuncName)) {
        setPythonExecError(PYTHON_FUNC_NAME_ERROR);
        return setErrorLineNumbers([{ row: 0, type: 'error', text: PYTHON_FUNC_NAME_ERROR }]);
      }

      if (pythonExecError) {
        setPythonExecError(null);
        setErrorLineNumbers([]);
      }

      if (!isSubmitTriggeredFunction) {
        setIsRunOutputTableVisible(false);
        setCurrentOperation(Operations.Run);
      }

      resetExpectedPythonOutput();
      setIsPythonExecuting(true);

      expectedPythonOutput.map(async ({ expectedOutput, testQuery: { args }, index }) => {
        const runPythonTestCaseQuery = `${userFuncName}(${args.map(({ value }) => value)})`;
        const testCaseQuery = `${editorValue}
        
${runPythonTestCaseQuery}`;

        const initialOutput = {
          index,
          testQuery: { funcName: userFuncName, args },
          expectedOutput,
        };

        try {
          pythonWorker.current?.postMessage({ pythonQuery: testCaseQuery, ...initialOutput });
        } catch (error: any) {
          Logger.error(error);

          return {
            ...initialOutput,
            isValid: false,
          };
        }
      });

      pythonRunnerTimer.current = setTimeout(() => {
        resetExpectedPythonOutput();
        pythonWorker.current?.terminate();
        pythonWorker.current = null;
        setIsPythonRunnerReady(false);
        setIsPythonExecuting(false);
        setPythonExpectedCasesExecuted(false);
        setIsSubmittedTableLoading(false);
        setPythonExecError(PYTHON_TIMEOUT_ERROR);
        setSubmittedQueryError(PYTHON_TIMEOUT_ERROR);
        setCurrentOperation(Operations.Watch);
      }, PYTHON_TIMEOUT_ERROR_DELAY);

      ActiveCampaignService.setLastEngagementDateOnRunPythonSolution();
    },

    [
      editorValue,
      expectedPythonOutput,
      pythonExecError,
      resetExpectedPythonOutput,
      setCurrentOperation,
      setIsRunOutputTableVisible,
      setIsSubmittedTableLoading,
      setPythonExpectedCasesExecuted,
      setSubmittedQueryError,
    ],
  );

  const runTests = useCallback(async () => {
    if (currentOperation !== Operations.Watch) {
      return;
    }
    setCurrentOperation(Operations.Run);
    setIsOutputTableLoading(true);

    try {
      const outputResponse = await SubmitQueryService.runQuery(questionId, editorValue, currentRuntime);

      setRunResult(outputResponse);
      setIsRunOutputTableVisible(true);
      setOutputTableError(null);
    } catch (error: any) {
      if (error.response?.status !== StatusCode.BadRequest) {
        Logger.error(error);
      }

      setIsRunOutputTableVisible(true);
      setOutputTableError(error.response?.data?.error || 'Something went wrong :/');
    } finally {
      setCurrentOperation(Operations.DisabledRun);
      setIsOutputTableLoading(false);
    }
  }, [
    currentOperation,
    setCurrentOperation,
    setIsOutputTableLoading,
    questionId,
    editorValue,
    currentRuntime,
    setRunResult,
    setIsRunOutputTableVisible,
    setOutputTableError,
  ]);

  const hideTestResults = useCallback(() => {
    setIsRunOutputTableVisible(false);
    setCurrentOperation(Operations.Watch);
  }, [setCurrentOperation, setIsRunOutputTableVisible]);

  const preSubmitOperation = useCallback(() => {
    setCurrentOperation(Operations.Submit);
    // @ts-ignore
    setTabIndex(isGeneralAssemblyMvp ? GeneralAssemblyQuestionTab.QuestionSubmissions : Tab.QuestionSubmissions);
    setIsSubmittedTableLoading(true);
  }, [isGeneralAssemblyMvp, setCurrentOperation, setIsSubmittedTableLoading, setTabIndex]);

  const submitPythonQuery = useCallback(async () => {
    if (currentOperation === Operations.Submit) {
      return;
    }
    resetExpectedPythonOutput();
    setPythonExecError(null);
    preSubmitOperation();

    setIsRunOutputTableVisible(false);
    runPythonCode(true);
  }, [currentOperation, preSubmitOperation, resetExpectedPythonOutput, runPythonCode, setIsRunOutputTableVisible]);

  const submitQuery = useCallback(async () => {
    if (currentOperation === Operations.Submit) {
      return;
    }
    preSubmitOperation();

    try {
      const submitResponse = await SubmitQueryService.submitQuery(questionId, editorValue, currentRuntime);

      setSubmittedQueryResult(submitResponse);
      setSubmittedQueryError(null);
      setSubmissionsState((prevState) => ({
        ...prevState,
        data: [submitResponse.submission, ...prevState.data],
      }));
    } catch (error: any) {
      if (error.response?.status !== StatusCode.BadRequest) {
        Logger.error(error);
      }

      setSubmittedQueryError(null);
      if (error.response?.data?.submission) {
        setSubmissionsState((prevState) => ({
          ...prevState,
          data: [error.response.data.submission, ...prevState.data],
        }));
      }
      if (error.response?.data?.payload) {
        setSubmittedQueryResult(error.response.data);
      } else {
        setSubmittedQueryError(error.response?.data?.error || 'Something went wrong :/');
      }
    } finally {
      setCurrentOperation(Operations.DisabledAll);
      setIsSubmittedTableLoading(false);
    }
  }, [
    currentOperation,
    preSubmitOperation,
    questionId,
    editorValue,
    currentRuntime,
    setSubmittedQueryResult,
    setSubmittedQueryError,
    setSubmissionsState,
    setCurrentOperation,
    setIsSubmittedTableLoading,
  ]);

  const saveEditorValueToCache = useMemo(
    () =>
      debounce((value: string) => {
        localStorage.setItem(editorValueCacheKey, value);
      }, 1000),
    [editorValueCacheKey],
  );

  const changeEditorValue = useCallback(
    (newValue?: string) => {
      setEditorValue(newValue || '');
      saveEditorValueToCache(newValue || '');
      setCurrentOperation(Operations.Watch);
    },
    [saveEditorValueToCache, setCurrentOperation],
  );

  const runCodeHandler = useCallback(() => {
    MPTracker.track(MixpanelEvent.ProblemRun, mixPanelData);

    return isPythonQuestion ? runPythonCode() : runTests();
  }, [isPythonQuestion, mixPanelData, runPythonCode, runTests]);

  const submitQueryHandler = useCallback(() => {
    MPTracker.track(MixpanelEvent.ProblemSubmit, mixPanelData);

    return isPythonQuestion ? submitPythonQuery() : submitQuery();
  }, [isPythonQuestion, mixPanelData, submitPythonQuery, submitQuery]);

  useEffect(() => {
    const savedEditorInput = localStorage.getItem(editorValueCacheKey);
    if (savedEditorInput) {
      setEditorValue(savedEditorInput);
    }
  }, [editorValueCacheKey]);

  const changeRuntime = (runtime: SupportedRuntime) => {
    setCurrentOperation(Operations.Watch);
    setCurrentRuntime(runtime);
  };

  return (
    <Flex overflowY="auto" pb={{ base: '16px', md: '0' }} direction="column" className={codeEditorClassName}>
      <CompanyInfo companyInfo={companyInfo} difficulty={difficulty} />

      <Flex
        flexDir="column"
        width="100%"
        height="100%"
        border="1px solid"
        borderColor="gray.300"
        borderTopRadius="8px"
        borderBottom="none"
      >
        <HStack padding="8px 12px" gap="6px">
          <Text fontWeight={600}>Input</Text>

          {isPythonQuestion ? (
            <Text color="black.300" fontWeight={600}>
              Python
            </Text>
          ) : (
            <MenuQuestionRuntimes
              supportedRuntimes={supportedRuntimes}
              currentRuntime={currentRuntime}
              changeRuntime={changeRuntime}
            />
          )}
        </HStack>

        <DynamicCodeEditor
          value={editorValue}
          onChange={changeEditorValue}
          isPythonMode={isPythonQuestion}
          errorAnnotations={errorLineNumbers}
          runtime={currentRuntime}
        />
      </Flex>

      <CodeEditorControls
        onTestsRun={runCodeHandler}
        onSubmit={submitQueryHandler}
        isRunLoading={isRunLoading}
        isRunDisabled={!editorValue || currentOperation !== Operations.Watch}
        isSubmitLoading={isSubmitLoading}
        isSubmitDisabled={!editorValue}
        isMobile={isMobile}
        isPythonQuestion={isPythonQuestion}
        isLoading={isOutputTableLoading}
        result={tableResult}
        onChevronClick={hideTestResults}
        error={outputTableError || pythonExecError}
        isRunOutputTableVisible={isRunOutputTableVisible}
        isPythonCasesTabVisible={isPythonCasesTabVisible}
      />
    </Flex>
  );
};

export default CodeEditorTab;
