import { create } from 'zustand';
import { ExpectedPythonOutput, RunQueryResult } from '@/types/models';

interface RunQueryState {
  pythonExpectedCasesExecuted: boolean;
  setPythonExpectedCasesExecuted: (value: boolean) => void;
  tableResult: RunQueryResult | null;
  setResultTable: (tableResult: RunQueryResult) => void;
  expectedPythonOutput: ExpectedPythonOutput[];
  setItemToExpectedPythonOutput: (output: ExpectedPythonOutput) => void;
  resetExpectedPythonOutput: () => void;
  isRunOutputTableVisible: boolean;
  setIsRunOutputTableVisible: (isVisible: boolean) => void;
  isOutputTableLoading: boolean;
  setIsOutputTableLoading: (isLoading: boolean) => void;
  outputTableError: string | null;
  setOutputTableError: (error: string | null) => void;
  resetState: () => void;
}

export const useRunQueryStore = create<RunQueryState>((set) => ({
  pythonExpectedCasesExecuted: false,
  setPythonExpectedCasesExecuted: (pythonExpectedCasesExecuted) => set({ pythonExpectedCasesExecuted }),
  tableResult: null,
  setResultTable: (tableResult) => set({ tableResult }),
  expectedPythonOutput: [],
  setItemToExpectedPythonOutput: (output: ExpectedPythonOutput) => {
    set((state) => {
      if (state.expectedPythonOutput.find((value) => value.index === output.index)) {
        return {
          expectedPythonOutput: [
            ...state.expectedPythonOutput.filter((value) => value.index !== output.index),
            { ...output },
          ],
        };
      }

      return {
        expectedPythonOutput: [...state.expectedPythonOutput, { ...output }],
      };
    });
  },
  resetExpectedPythonOutput: () =>
    set({
      expectedPythonOutput: [],
    }),
  isRunOutputTableVisible: false,
  setIsRunOutputTableVisible: (isVisible) => set({ isRunOutputTableVisible: isVisible }),
  isOutputTableLoading: false,
  setIsOutputTableLoading: (isLoading) => set({ isOutputTableLoading: isLoading }),
  outputTableError: null,
  setOutputTableError: (error) => set({ outputTableError: error }),
  resetState: () =>
    set({
      tableResult: null,
      isRunOutputTableVisible: false,
      isOutputTableLoading: false,
      outputTableError: null,
    }),
}));
