import { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const ChatIcon: FC<IconProps> = (props: IconProps) => (
  <Icon viewBox="0 0 16 16" {...props}>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.5 5.674C14.0893 5.86333 14.5 6.426 14.5 7.072V9.92933C14.5 10.6867 13.9353 11.3293 13.18 11.3913C12.9533 11.4093 12.7267 11.426 12.5 11.4393V13.5L10.5 11.5C9.59733 11.5 8.704 11.4633 7.82 11.3913C7.62755 11.3758 7.44035 11.3209 7.27 11.23M13.5 5.674C13.397 5.64084 13.2905 5.61959 13.1827 5.61067C11.3973 5.46246 9.60271 5.46246 7.81733 5.61067C7.06333 5.67333 6.5 6.31533 6.5 7.072V9.92933C6.5 10.4873 6.80667 10.9827 7.27 11.23M13.5 5.674V4.42467C13.5 3.344 12.732 2.40733 11.66 2.268C10.2805 2.0892 8.89099 1.99968 7.5 2C6.09 2 4.70133 2.09133 3.34 2.268C2.268 2.40733 1.5 3.344 1.5 4.42467V8.57533C1.5 9.656 2.268 10.5927 3.34 10.732C3.72467 10.782 4.11133 10.8253 4.5 10.8613V14L7.27 11.23"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </Icon>
);
