import { Dispatch, FC, SetStateAction } from 'react';
import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { QuestionsFilterQueryKey, QuestionStatusFilter } from '@/constants';
import { useQuestionsFilterQuery } from '@/hooks';
import { ChevronDownIcon, getButtonConditionalHoverProps, getButtonConditionalStyleProps } from '@/components/common';

interface MenuFilterDifficultyProps {
  filterStatus: QuestionStatusFilter | null;
  setFilterStatus: Dispatch<SetStateAction<QuestionStatusFilter | null>>;
  onOpen?: () => void;
  isOpen?: boolean;
  isDisabled?: boolean;
}

const MenuFilterStatus: FC<MenuFilterDifficultyProps> = (props) => {
  const { filterStatus, setFilterStatus, isDisabled, onOpen, isOpen: isMenuOpen } = props;

  const { setFilterQuery } = useQuestionsFilterQuery();

  const setFilterStatusHandler = async (status: QuestionStatusFilter | null) => {
    await setFilterQuery(QuestionsFilterQueryKey.Status, status);
    setFilterStatus(status);
  };

  return (
    <Menu isOpen={isMenuOpen} onOpen={onOpen}>
      {({ isOpen }) => (
        <>
          <MenuButton
            as={Button}
            isActive={isOpen}
            iconSpacing="6px"
            rightIcon={<ChevronDownIcon color="inherit" />}
            minW={{ base: '100%', md: 'auto' }}
            width="100%"
            borderRadius="8px"
            border="1px solid"
            variant="wrapper"
            {...getButtonConditionalStyleProps(!!filterStatus)}
            _hover={getButtonConditionalHoverProps(!!filterStatus)}
            isDisabled={isDisabled}
          >
            {filterStatus || 'Status'}
          </MenuButton>
          <MenuList>
            <MenuItem onClick={() => setFilterStatusHandler(null)}>All</MenuItem>
            <MenuItem onClick={() => setFilterStatusHandler(QuestionStatusFilter.Solved)}>
              {QuestionStatusFilter.Solved}
            </MenuItem>
            <MenuItem onClick={() => setFilterStatusHandler(QuestionStatusFilter.Error)}>
              {QuestionStatusFilter.Error}
            </MenuItem>
            <MenuItem onClick={() => setFilterStatusHandler(QuestionStatusFilter.NotStarted)}>
              {QuestionStatusFilter.NotStarted}
            </MenuItem>
          </MenuList>
        </>
      )}
    </Menu>
  );
};

export default MenuFilterStatus;
