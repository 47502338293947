import { FC, ReactNode } from 'react';
import Link from 'next/link';
import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react';
import { NotebookIcon } from '@/assets/icons';
import { Routes } from '@/constants';
import styles from './PracticeCard.module.scss';

const QUESTION_SLUG_TEMPLATE_STRING = 'language-practice-cardQuestionSlug=';

interface PracticeCardProps {
  match: RegExpMatchArray | null;
  children: ReactNode;
}

const PracticeCard: FC<PracticeCardProps> = (props) => {
  const { children, match } = props;

  const slug = match?.input?.slice(QUESTION_SLUG_TEMPLATE_STRING.length);

  return (
    <Flex px="8px">
      <Link href={`${Routes.Questions}/${slug}`} className={styles.cardWrapper}>
        <Flex>
          <Box mt="12px">
            <NotebookIcon />
          </Box>
          <Box className={styles.cardBody}>
            <Heading fontSize="24px">Practice Problem</Heading>
            <Text whiteSpace="initial" my="12px" noOfLines={3} fontFamily="var(--chakra-fonts-body)">
              {children}
            </Text>
            <Button colorScheme="red" w="140px" _hover={{}}>
              Try it out
            </Button>
          </Box>
        </Flex>
      </Link>
    </Flex>
  );
};

export default PracticeCard;
