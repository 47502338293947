import { FC } from 'react';
import { Text, TextProps } from '@chakra-ui/react';

interface DividingPointProps extends TextProps {
  classname?: string;
}

const DividingPoint: FC<DividingPointProps> = ({ classname, ...restProps }) => (
  <Text className={classname} {...restProps}>
    ·
  </Text>
);

export default DividingPoint;
