import { FC, useState } from 'react';
import {
  Button,
  Collapse,
  Flex,
  FlexProps,
  Tab as TabChakra,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { nanoid } from 'nanoid';
import { ExpectedPythonOutput } from '@/types/models';
import { CodeError } from '@/components/pages/question';
import { QuestionStatus } from '@/constants';
import { CheckIcon, ChevronDownIcon, CrossIcon } from '@/components/common';
import { useRunQueryStore } from '@/stores';

interface PythonQueryTableProps extends FlexProps {
  passedPythonOutput?: ExpectedPythonOutput[];
  status?: QuestionStatus;
  hideTestResults?: () => void;
  error?: string | null;
}

const STDOUT_BASE_LINES = 8;

const PythonTestCaseTab: FC<ExpectedPythonOutput> = ({
  testQuery,
  expectedOutput,
  usersOutput,
  wrongCharsAt,
  stdOut,
}) => {
  const { isOpen, onToggle } = useDisclosure();
  const isShouldCollapseStdOut = stdOut && stdOut?.length > STDOUT_BASE_LINES;

  return (
    <Flex direction="column" gap="6px" overflow="auto">
      <Text fontSize="16px" lineHeight="24px" color="black.200">
        Input
      </Text>
      <Flex direction="column" gap="8px">
        {testQuery.args.map(({ name, value }) => (
          <Flex key={name} direction="column" padding="6px 12px" backgroundColor="gray.100" borderRadius="6px">
            <Text color="gray">{name}=</Text>
            <Text fontWeight={600}>{value}</Text>
          </Flex>
        ))}
      </Flex>
      <Flex direction="column" gap="6px">
        <Text fontSize="16px" lineHeight="24px" color="black.200">
          Expected
        </Text>
        <Flex
          alignItems="center"
          padding="8px 12px"
          backgroundColor="gray.100"
          borderRadius="6px"
          fontWeight={600}
          letterSpacing="2px"
        >
          {Array.isArray(expectedOutput) ? (
            <>
              [
              {expectedOutput.map((item, index) => (
                <Text
                  key={nanoid()}
                  as="span"
                  color={wrongCharsAt?.includes(index) ? 'green' : 'initial'}
                  _notLast={{
                    _after: {
                      content: "','",
                      color: 'initial',
                    },
                  }}
                >
                  {item}
                </Text>
              ))}
              ]
            </>
          ) : (
            expectedOutput
          )}
        </Flex>
      </Flex>
      {stdOut?.length ? (
        <Flex direction="column" gap="6px">
          <Text fontSize="16px" lineHeight="24px" color="black.200">
            Stdout
          </Text>
          <Flex
            position="relative"
            flexDir="column"
            padding="8px 12px"
            backgroundColor="gray.100"
            borderRadius="6px"
            fontWeight={600}
            letterSpacing="2px"
            onClick={onToggle}
            cursor={isShouldCollapseStdOut ? 'pointer' : 'initial'}
          >
            {stdOut.slice(0, STDOUT_BASE_LINES).map((item, indx) => (
              <Text key={indx} _last={!isOpen && isShouldCollapseStdOut ? { _after: { content: '"..."' } } : {}}>
                {item}
              </Text>
            ))}
            {isShouldCollapseStdOut && (
              <>
                <Collapse in={isOpen}>
                  {stdOut.slice(STDOUT_BASE_LINES).map((item, indx) => (
                    <Text key={indx}>{item}</Text>
                  ))}
                </Collapse>
                <Button
                  fontSize="14px"
                  fontWeight={500}
                  height="auto"
                  padding="5px 6px"
                  backgroundColor="white"
                  position="absolute"
                  bottom="6px"
                  right="6px"
                  rightIcon={
                    <ChevronDownIcon
                      transform={isOpen ? 'rotate(180deg)' : 'rotate(360deg)'}
                      transition="all 0.2s linear"
                    />
                  }
                >
                  View {isOpen ? 'Less' : 'More'}
                </Button>
              </>
            )}
          </Flex>
        </Flex>
      ) : null}
      {usersOutput && (
        <Flex direction="column" gap="6px">
          <Text fontSize="16px" lineHeight="24px" color="black.200">
            Output
          </Text>
          <Flex padding="8px 12px" backgroundColor="gray.100" borderRadius="6px" fontWeight={600} letterSpacing="2px">
            {Array.isArray(usersOutput) ? (
              <>
                [
                {usersOutput.map((item, index) => (
                  <Text
                    key={nanoid()}
                    as="span"
                    color={wrongCharsAt?.includes(index) ? 'red' : 'initial'}
                    _notLast={{
                      _after: {
                        content: "','",
                        color: 'initial',
                      },
                    }}
                  >
                    {item}
                  </Text>
                ))}
                ]
              </>
            ) : (
              usersOutput
            )}
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export const PythonQueryTable: FC<PythonQueryTableProps> = ({
  hideTestResults,
  passedPythonOutput,
  error,
  status,
  ...containerProps
}) => {
  const [tabIndex, setTabIndex] = useState<number>(0);
  const { expectedPythonOutput } = useRunQueryStore();

  const pythonOutput = passedPythonOutput || expectedPythonOutput;

  const isExecutedWithError = !pythonOutput.find((value) => value);
  const isMismatched = status === QuestionStatus.Mismatched || status === QuestionStatus.Wrong;

  if (!pythonOutput.length || isExecutedWithError || error) {
    return <CodeError errorMessage={error || 'Error during run python query'} />;
  }

  return (
    <Flex direction="column" {...containerProps}>
      {isMismatched && (
        <CodeError
          title="Mismatched"
          errorMessage="Your query's output doesn't match with the solution's output!"
          mb="10px"
        />
      )}
      {hideTestResults && (
        <Flex
          pl="6px"
          pr="8px"
          mt="16px"
          h="26px"
          borderRadius="8px"
          justifyContent="space-between"
          alignItems="center"
          onClick={hideTestResults}
          _hover={{
            cursor: 'pointer',
            backgroundColor: 'gray.200',
          }}
        >
          <Text fontWeight="500">Hide</Text>
          <ChevronDownIcon fontSize="20px" />
        </Flex>
      )}
      <Tabs index={tabIndex} onChange={setTabIndex} variant="outline">
        <TabList>
          {pythonOutput
            .sort((elem, elemNext) => elem.index - elemNext.index)
            .map(({ index, isValid }) => (
              <TabChakra as={Flex} key={index} gap="8px" padding="6px 12px" cursor="pointer">
                <Text
                  color={tabIndex === index - 1 ? 'black.500' : 'black.200'}
                  fontSize="16px"
                  lineHeight="24px"
                  minWidth="50px"
                >
                  Test case {index}
                </Text>
                {isValid ? <CheckIcon color="green.200" /> : <CrossIcon color="red.200" fontSize="12px" />}
              </TabChakra>
            ))}
        </TabList>

        <TabPanels>
          {pythonOutput
            .sort((elem, elemNext) => elem.index - elemNext.index)
            .map((item, idx) => (
              <TabPanel key={idx}>
                <PythonTestCaseTab {...item} />
              </TabPanel>
            ))}
        </TabPanels>
      </Tabs>
    </Flex>
  );
};
