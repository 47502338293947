export const TrafficSourceTrackingInputs = () => (
  <>
    <input type="hidden" id="hiddenClientID" name="Client ID" value="" />
    <input type="hidden" id="hiddenGCLID" name="GCLID" value="" />
    <input type="hidden" id="hiddenTrafficSource" name="Traffic Source" value="" />
    <input type="hidden" id="hiddenTrafficMedium" name="Traffic Medium" value="" />
    <input type="hidden" id="hiddenTrafficCampaign" name="Traffic Campaign" value="" />
    <input type="hidden" id="hiddenTrafficContent" name="Traffic Content" value="" />
    <input type="hidden" id="hiddenTrafficKeyword" name="Traffic Keyword" value="" />
    <input type="hidden" id="hiddenFullReferrer" name="Full Referrer" value="" />
    <input type="hidden" id="client_user_agent" name="Client User Agent" value="" />
    <input type="hidden" id="client_ip_address" name="Client IP Address" value="" />
    <input type="hidden" id="fbp" name="Browser Id" value="" />
    <input type="hidden" id="fbc" name="Click Id" value="" />
  </>
);
