import { FC } from 'react';
import { Flex, Image, Text } from '@chakra-ui/react';
import { getApiUrlPrefix } from '@/utils';
import { CompanyInfo } from '@/types/models';

export const CompanyInfoBlock: FC<Partial<CompanyInfo>> = ({ name, icon }) => (
  <Flex alignItems="center" gap="6px">
    {icon && <Image src={getApiUrlPrefix(icon)} alt={`${name} icon`} width="16px" height="16px" mt="2px" />}

    {name && (
      <Text color="black.500" fontWeight={600}>
        {name}
      </Text>
    )}
  </Flex>
);
