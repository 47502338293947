import { FC, useEffect, useMemo, useState } from 'react';
import Link from 'next/link';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Box, Button, Flex, Grid, Heading, Text } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import { LoadingState } from '@/types';
import { User } from '@/types/models';
import { CouponService, Logger, UserService } from '@/services';
import { Loader } from '@/components/common';
import { useUser } from '@/hooks';
import { SectionAnchor } from '@/utils';
import { ExternalLinks, REFERRAL_CODE_KEY, Routes, SITE_URL } from '@/constants';
import UnlockedUnit from './UnlockedUnit';
import styles from './ReferralSection.module.scss';

enum ReferralLevel {
  FirstLevel = 3,
  SecondLevel = 10,
  ThirdLevel = 50,
}

interface ReferralSectionProps {
  shouldScrollBelowToSeeQuestions?: boolean;
}

const ReferralSection: FC<ReferralSectionProps> = (props) => {
  const { shouldScrollBelowToSeeQuestions } = props;
  const { user } = useUser();
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const [{ fetched: areUsersFetched, data: referralUsers }, setUserReferralState] = useState<LoadingState<User[]>>({
    loading: false,
    fetched: false,
    data: [],
  });
  const [coupon, setCoupon] = useState<string | null>(null);

  const fullReferralLink = `${SITE_URL}?${REFERRAL_CODE_KEY}=${user?.referralCode}`;
  const referralLinkForDisplay = fullReferralLink.replace('https://', '');
  const referralAmount = referralUsers.length;

  const copyLink = () => setIsLinkCopied(true);

  const referralScoreLimit = useMemo(() => {
    if (referralAmount < ReferralLevel.FirstLevel) {
      return `/${ReferralLevel.FirstLevel}`;
    }
    if (referralAmount < ReferralLevel.SecondLevel) {
      return `/${ReferralLevel.SecondLevel}`;
    }
    if (referralAmount < ReferralLevel.ThirdLevel) {
      return `/${ReferralLevel.ThirdLevel}`;
    }

    return null;
  }, [referralAmount]);

  useEffect(() => {
    if (!user) {
      return;
    }
    setUserReferralState((prevState) => ({ ...prevState, loading: true }));

    (async () => {
      try {
        const users = await UserService.getUsersByInviterCode(user?.referralCode, {
          fields: ['id'],
        });
        setUserReferralState({ loading: false, fetched: true, data: users });
      } catch (error) {
        Logger.error(error);
        setUserReferralState({ loading: false, fetched: true, data: [] });
      }
      if (!areUsersFetched || referralAmount < ReferralLevel.SecondLevel) {
        return;
      }
      try {
        const bonusCoupon = await CouponService.getCoupon();

        if (bonusCoupon) {
          setCoupon(bonusCoupon);
        }
      } catch (error) {
        Logger.error(error);
      }
    })();
  }, [user, referralAmount, areUsersFetched]);

  useEffect(() => {
    if (!isLinkCopied) {
      return;
    }

    const timer = setTimeout(() => {
      setIsLinkCopied(false);
    }, 2 * 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [isLinkCopied]);

  return (
    <Flex className={styles.referralWrapper} id={SectionAnchor.ReferralSection}>
      <Heading as="h2" variant="main">
        Refer Friends To Win BIG 🎁
      </Heading>
      <Text as="b">
        Every friend that signs-up and verifies their email address helps you access bonus questions and exclusive
        prizes!
      </Text>
      {areUsersFetched ? (
        <>
          <Grid className={styles.referralLinkWrapper}>
            <Text
              isTruncated
              as="b"
              textAlign="center"
              px="14px"
              py="6px"
              border="1px solid"
              borderColor="gray.300"
              borderRadius="19px"
            >
              {referralLinkForDisplay}
            </Text>
            <CopyToClipboard text={fullReferralLink} onCopy={copyLink}>
              <Button colorScheme={isLinkCopied ? 'green' : 'red'}>
                {isLinkCopied ? <CheckIcon /> : 'Copy To Clipboard'}
              </Button>
            </CopyToClipboard>
          </Grid>
          <Heading as="h3" my="26px" variant="secondary">
            Your Referral Count: {referralUsers.length}
            {referralScoreLimit}
          </Heading>
        </>
      ) : (
        <Loader height="140px" />
      )}
      <Box className={styles.subSection}>
        <Heading as="h3" variant="secondary">
          Refer 3 Friends:
        </Heading>
        <Text>You Get 10 Bonus Statistics & SQL Interview Questions</Text>
        {referralAmount >= ReferralLevel.FirstLevel ? (
          <>
            <UnlockedUnit>
              {shouldScrollBelowToSeeQuestions ? (
                'scroll below to see bonus SQL questions!'
              ) : (
                <Box display="inline-block">
                  <Text display="inline-block">see bonus questions at the bottom of</Text>{' '}
                  <Link
                    href={`${Routes.Questions}#${SectionAnchor.BonusQuestions}`}
                    scroll={true}
                    className={styles.link}
                    rel="noopener noreferrer"
                  >
                    questions page
                  </Link>
                </Box>
              )}
            </UnlockedUnit>
            <UnlockedUnit>
              <Box display="inline-block">
                <Text display="inline-block">✨NEW✨</Text>{' '}
                <Link href={Routes.StatisticsQuestions} className={styles.link} rel="noopener noreferrer">
                  Statistics Interview questions!
                </Link>
              </Box>
            </UnlockedUnit>
          </>
        ) : null}
      </Box>
      <Box className={styles.subSection}>
        <Heading as="h3" variant="secondary">
          Refer 10 Friends:
        </Heading>
        <Box>
          You Get Free Access to the Video Course{' '}
          <Link
            href={ExternalLinks.AceTheDataScienceLink}
            className={styles.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            Ace the Data Job Hunt
          </Link>{' '}
          ($30 Value)
        </Box>
        {coupon && referralAmount >= ReferralLevel.SecondLevel ? (
          <UnlockedUnit>
            use coupon code <b>{coupon}</b>!
          </UnlockedUnit>
        ) : null}
      </Box>
      <Heading as="h3" variant="secondary">
        Refer 50 Friends:
      </Heading>
      <Text>Book a 1-Hour, 1:1 Career Coaching Call with Nick Singh for FREE (Normally $200)</Text>
      {referralAmount >= ReferralLevel.ThirdLevel ? (
        <UnlockedUnit>
          Here&apos;s the{' '}
          <Link
            href={ExternalLinks.CoachingCallWithNickLink}
            className={styles.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            link
          </Link>{' '}
          to schedule a call with me!
        </UnlockedUnit>
      ) : null}
    </Flex>
  );
};

export default ReferralSection;
