import React, { FC, useMemo, useState } from 'react';
import { Button, Divider, Flex, ScaleFade, Text } from '@chakra-ui/react';
import { Hint } from '@/types/models';
import { Markdown } from '@/components/common';

interface HintsProps {
  hints: Hint[];
}

const Hints: FC<HintsProps> = (props) => {
  const { hints } = props;

  const [hintNumber, setHintNumber] = useState<number | null>(null);
  const displayedHints = useMemo(
    () => (hintNumber ? hints.filter((hint, index) => index < hintNumber) : null),
    [hintNumber, hints],
  );
  const isButtonVisible = displayedHints?.length !== hints.length;

  const showHintHandler = () => {
    setHintNumber((prevState) => (prevState ? prevState + 1 : 1));
  };

  return (
    <>
      {hintNumber &&
        displayedHints?.map((hint, index) => (
          <ScaleFade key={index} in={true}>
            <Flex flexDirection="column">
              <Flex alignItems="center">
                <Divider />
                <Text style={{ whiteSpace: 'nowrap' }} fontSize="16px" fontWeight="bold" m="10px">
                  Hint #{index + 1}
                </Text>
                <Divider />
              </Flex>
              <Markdown>{hint.text}</Markdown>
            </Flex>
          </ScaleFade>
        ))}
      {isButtonVisible && (
        <Button mb="16px" colorScheme="red" variant="outline" onClick={showHintHandler}>
          Gimme a Hint
        </Button>
      )}
    </>
  );
};

export default Hints;
