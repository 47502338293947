import { FC } from 'react';

interface LinkedInIconProps {
  className?: string;
  color?: string;
}

const LinkedInIcon: FC<LinkedInIconProps> = (props) => {
  const { className, color = '#0288d1' } = props;

  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px">
      <path
        fill={color}
        d="M8.421 14h.052 0C11.263 14 13 12 13 9.5 12.948 6.945 11.263 5 8.526 5 5.789 5 4 6.945 4 9.5 4 12 5.736 14 8.421 14zM4 17H13V43H4zM44 26.5c0-5.247-4.253-9.5-9.5-9.5-3.053 0-5.762 1.446-7.5 3.684V17h-9v26h9V28h0c0-2.209 1.791-4 4-4s4 1.791 4 4v15h9C44 43 44 27.955 44 26.5z"
      />
    </svg>
  );
};

export default LinkedInIcon;
