import { FC, ReactNode } from 'react';
import { Routes } from '@/constants';
import { AppLink } from '@/components/common';
import { LinkProps as ChakraLinkProps } from '@chakra-ui/react';
import { LinkProps as NextLinkProps } from 'next/link';

interface TableLinkProps extends ChakraLinkProps, Omit<NextLinkProps, 'as' | 'href'> {
  isCentered?: boolean;
  questionSlug: string;
  isGeneralAssemblyMvp?: boolean;
  children: ReactNode;
}

const TableLink: FC<TableLinkProps> = ({
  questionSlug,
  isCentered = false,
  children,
  isGeneralAssemblyMvp,
  ...styleProps
}) => {
  const linkPath = isGeneralAssemblyMvp
    ? Routes.GeneralAssemblyQuestion
    : Routes.Question.replace('{questionSlug}', questionSlug);

  const isContentCentered = isCentered ? { justifyContent: 'center' } : '';

  return (
    <AppLink
      fontSize="16px"
      lineHeight="24px"
      href={linkPath}
      display="flex"
      alignItems="center"
      h="100%"
      w="100%"
      py="0"
      _hover={{ textDecor: 'none' }}
      sx={{
        ...isContentCentered,
      }}
      {...styleProps}
    >
      {children}
    </AppLink>
  );
};

export default TableLink;
