import { create } from 'zustand';

interface VerificationState {
  isVerificationCheckLoading: boolean;
  isVerificationCheckDone: boolean;
  shouldAskToVerify: boolean;
  setIsVerificationCheckLoading: (isLoading: boolean) => void;
  setIsVerificationCheckDone: (isDone: boolean) => void;
  askToVerify: () => void;
  clearAskToVerify: () => void;
}

export const useVerificationStore = create<VerificationState>((set) => ({
  isVerificationCheckLoading: false,
  isVerificationCheckDone: false,
  shouldAskToVerify: false,
  setIsVerificationCheckLoading: (isLoading) =>
    set({
      isVerificationCheckLoading: isLoading,
    }),
  setIsVerificationCheckDone: (isDone) =>
    set({
      isVerificationCheckDone: isDone,
    }),
  askToVerify: () => set({ shouldAskToVerify: true }),
  clearAskToVerify: () => set({ shouldAskToVerify: false }),
}));
