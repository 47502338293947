import { FC } from 'react';
import { Container, ContainerProps } from '@chakra-ui/react';
import styles from './Loader.module.scss';

const Loader: FC<ContainerProps> = (styleProps) => {
  return (
    <Container {...styleProps} display="flex" alignItems="center" justifyContent="center">
      <div className={styles.loader}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </Container>
  );
};

export default Loader;
