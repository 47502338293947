import { FC, MutableRefObject } from 'react';
import Link from 'next/link';
import { Button, Center, Flex, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import { useUser } from '@/hooks';
import { SignInButton, SignOutButton } from '@clerk/nextjs';
import { getUserName } from '@/utils';
import { AppLink, Avatar } from '@/components/common';
import { Routes } from '@/constants';
import { useRouter } from 'next/router';
import { handleSaveRedirectUrl } from '@/utils/handleSaveRedirectUrl';
import { HeaderNavItem } from './Header';

interface BurgerMenuProps {
  isOpen: boolean;
  menuItems: HeaderNavItem[];
  forwardedRef?: MutableRefObject<HTMLDivElement | null>;
}

export const BurgerMenu: FC<BurgerMenuProps> = ({ menuItems, isOpen, forwardedRef }) => {
  const router = useRouter();
  const { user, isAuthInitialized, isLoading, logout } = useUser();
  const { asPath } = useRouter();
  const userName = user ? getUserName(user) : '';

  const handleLogout = () => {
    logout();
  };

  return (
    <Flex
      ref={forwardedRef}
      position="fixed"
      top="0px"
      left="0px"
      display={{ base: 'flex', lg: 'none' }}
      flexDirection="column"
      width="100vw"
      height={{ base: '100%' }}
      maxHeight="calc(100vh - 56px)"
      overflowY="scroll"
      borderBottom={{ md: 'md' }}
      alignItems="center"
      backgroundColor="gray.100"
      transition="all 0.5s"
      transform={`translateY(calc(${isOpen ? '0%' : '-100%'} + 56px))`}
      zIndex={30}
    >
      <UnorderedList
        width="100%"
        position="relative"
        as="nav"
        display="flex"
        flexDirection="column"
        alignItems="center"
        marginY="32px"
        gap="24px"
        listStyleType="none"
        marginInlineStart="0"
      >
        <Flex width="80%" maxW="180px" height="2px" backgroundColor="gray.400" />

        {menuItems.map(({ title, path }) => {
          return (
            <ListItem key={title}>
              <Link href={path}>
                <Text
                  fontSize={{ base: '20px', md: '22px' }}
                  textAlign="center"
                  _hover={{ textDecoration: 'underline' }}
                >
                  {title}
                </Text>
              </Link>
            </ListItem>
          );
        })}

        <Flex width="80%" maxW="180px" height="2px" backgroundColor="gray.400" />

        {isAuthInitialized && user && (
          <Center flexDir="column" gap="20px">
            <Center>
              <Text mr={3} textAlign="right" fontSize="20px" wordBreak="break-word">
                {userName}
              </Text>

              <Avatar size="sm" avatarId={user.avatar} userName={userName} />
            </Center>

            <AppLink href={Routes.ProfilePerformance} fontSize="18px" textAlign="center">
              Your Performance
            </AppLink>

            <AppLink href={Routes.AccountSettings} fontSize="18px" textAlign="center">
              Account Settings
            </AppLink>

            <AppLink href={Routes.Premium} fontSize="18px" textAlign="center">
              Premium
            </AppLink>

            <SignOutButton redirectUrl={router.asPath}>
              <Button
                minHeight="32px"
                colorScheme="red"
                isDisabled={isLoading}
                onClick={!isLoading ? handleLogout : undefined}
              >
                Log out
              </Button>
            </SignOutButton>
          </Center>
        )}

        {isAuthInitialized && !user && (
          <SignInButton>
            <Button maxH="32px" colorScheme="red" isDisabled={isLoading} onClick={() => handleSaveRedirectUrl(asPath)}>
              Sign In
            </Button>
          </SignInButton>
        )}
      </UnorderedList>
    </Flex>
  );
};
