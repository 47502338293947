import { ColorProps } from '@chakra-ui/react';
import { Difficulty } from '@/constants';

export const getDifficultyColor = (difficulty: Difficulty | null): ColorProps['color'] => {
  switch (difficulty) {
    case Difficulty.Easy:
      return 'green.400';
    case Difficulty.Medium:
      return 'orange.400';
    case Difficulty.Hard:
      return 'red.900';
    default:
      return;
  }
};
