import { FC, ReactNode } from 'react';
import { ScaleFade } from '@chakra-ui/react';

const UnlockedUnit: FC<{ children: ReactNode }> = (props) => {
  const { children } = props;
  return (
    <ScaleFade in={true}>
      <b>✅ Unlocked: </b>
      {children}
    </ScaleFade>
  );
};

export default UnlockedUnit;
