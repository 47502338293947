import { FC } from 'react';
import { Box, Button, Fade, Flex, HStack, Text, useDisclosure } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { useUser } from '@/hooks';
import { LoginModal, RunCodeIcon } from '@/components/common';
import { RunQueryResult } from '@/types/models';
import { PythonQueryTable, RunResult } from '@/components/pages/question';

interface RunResultProps {
  onChevronClick: () => void;
  result: RunQueryResult | null;
  error: string | null;
  isLoading: boolean;
  isRunOutputTableVisible: boolean;
}

interface CodeEditorControlsProps extends Partial<RunResultProps> {
  onTestsRun?: () => void;
  onSubmit: () => void;
  isRunLoading?: boolean;
  isRunDisabled?: boolean;
  isSubmitLoading?: boolean;
  isSubmitDisabled?: boolean;
  isMobile?: boolean;
  isPythonQuestion?: boolean;
  isPythonCasesTabVisible?: boolean;
}

const CodeEditorControls: FC<CodeEditorControlsProps> = (props) => {
  const {
    onTestsRun,
    onSubmit,
    isRunLoading,
    isRunDisabled,
    isSubmitLoading,
    isSubmitDisabled,
    isRunOutputTableVisible,
    isPythonCasesTabVisible,
    onChevronClick,
    result,
    error,
    isLoading,
  } = props;
  const { user } = useUser();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const isCodeQuestion = Boolean(onTestsRun);

  const handleSubmit = () => {
    if (!user) {
      onOpen();
      return;
    }
    onSubmit();
  };

  return (
    <Flex
      flexDir="column"
      padding="8px 12px"
      justifyContent="space-between"
      height="100%"
      flexBasis="210px"
      {...(isCodeQuestion && { border: '1px solid', borderColor: 'gray.300', borderBottom: 'none' })}
    >
      {isCodeQuestion && (
        <HStack width="100%" alignSelf="start" justifyContent="space-between" mb="8px">
          <Text fontWeight={600}>Output</Text>

          <ChevronDownIcon fontSize="20px" cursor="pointer" onClick={onChevronClick} />
        </HStack>
      )}

      {isRunOutputTableVisible && (
        // @ts-ignore
        <RunResult isLoading={isLoading} result={result} onChevronClick={onChevronClick} error={error} />
      )}

      {isPythonCasesTabVisible && <PythonQueryTable />}

      <Flex alignSelf="flex-end" mt="12px">
        <LoginModal isModalOpen={isOpen} onClose={onClose} />
        {isCodeQuestion && (
          <Box position="relative" mr="20px">
            <Fade in={!isRunDisabled && !isRunOutputTableVisible}>
              <RunCodeIcon
                display={{ base: 'none', lg: 'inline-block' }}
                width="246px"
                height="110px"
                position="absolute"
                bottom="14px"
                right="80px"
                zIndex={20}
              />
            </Fade>

            <Button
              isLoading={isRunLoading}
              isDisabled={isRunDisabled}
              colorScheme="red"
              variant="outline"
              onClick={onTestsRun}
              _hover={{ backgroundColor: 'pink.150' }}
            >
              Run Code
            </Button>
          </Box>
        )}
        <Button isLoading={isSubmitLoading} isDisabled={isSubmitDisabled} colorScheme="red" onClick={handleSubmit}>
          Submit
        </Button>
      </Flex>
    </Flex>
  );
};

export default CodeEditorControls;
